export function sortArrayASC( arrayToSort, itemPropToSortBy, isNumericCompare ) {
	arrayToSort = arrayToSort.slice().sort( ( itemA, itemB ) =>
		isNumericCompare ?
			itemA[itemPropToSortBy] - itemB[itemPropToSortBy] :
			itemA[itemPropToSortBy].localeCompare( itemB[itemPropToSortBy] )
	);

	return arrayToSort;
}

export function getCurrPrevNext( currId, collectionArray, doWrap = false, numberPrevNext = 1 ) {
	let outCurrPrevNext = {
		currId: currId,
		prevId: null,
		nextId: null,
		idArray: [],
	};

	if ( currId !== null && currId !== -1 ) {
		// Prev
		for ( let i = -numberPrevNext; i < 0; i++ ) {
			let counterId = currId + i;
			let prevXId;
			if ( counterId < 0 )
				prevXId = collectionArray.length + counterId;
			else
				prevXId = counterId;

			outCurrPrevNext.idArray.push( prevXId );
		}

		// Curr
		outCurrPrevNext.idArray.push( currId );

		// Next
		for ( let i = 1; i <= numberPrevNext; i++ ) {
			let counterId = currId + i;
			let nextXId;
			if ( counterId > collectionArray.length - 1 )
				nextXId = counterId - collectionArray.length;
			else
				nextXId = counterId;

			outCurrPrevNext.idArray.push( nextXId );
		}

		outCurrPrevNext.prevId =
			currId === 0 ?
				(doWrap ? collectionArray.length - 1 : null) :
				currId - 1;
		outCurrPrevNext.nextId =
			currId === collectionArray.length - 1 ?
				(doWrap ? 0 : null) :
				currId + 1;
	}

	return outCurrPrevNext;
}