import React, {
	useState,
} from "react";

import {
	useQuery,
	gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
	AnimatePresence,
} from "framer-motion";


import arrowLineRight from "../img/arrow_line_right.svg";


import ThemeData from "../data/ThemeData";
import ConstantData from "../data/ConstantData";


import ButtonOutline from "./ButtonOutline";



const unitPropertiesQuery = gql`
	query unitProperties {
		unitFloors {
			id
			name
			number
			units {
				id
				unit_number
			}
		}
		unitBuildings {
			id
			name
			units {
				id
				unit_number
			}
		}
		unitHomeTypes {
			id
			name
			units {
				id
				unit_number
			}
		}
		unitPlanTypes {
			id
			name
			units {
				id
				unit_number
			}
		}
		unitExposures {
			id
			direction
			directionAbbrev
			units {
				id
				unit_number
			}
		}
	}
`;


const useStyles = createUseStyles(
	{
		borderWrapper: {
			position: 'absolute',
			top: 150,
			left: 100,

			padding: 5,

			overflow: 'hidden',

			border: '1px white solid',

			background: 'rgba( 0, 0, 0, 0 )',
		},
		expandRetractButtonWrapper: {
			position: 'absolute',
			top: 0,
			right: 0,

			// transition: 'all .33s ease',

			// '&.isRetract': {
			// 	transform: 'rotate( 180deg )'
			// },

		},
		expandRetractButton: {
			width: 75,
			height: 75,

			background: 'no-repeat center',
			backgroundImage: `url( ${arrowLineRight} )`,

			'&.isToExpand': {
				width: 110,
			},
		},
		filterTitle: {
			position: 'absolute',
			// display: 'none',

			marginTop: 39,

			textTransform: 'uppercase',

			'& h4': {
				margin: 0,
			},
		},
		wrapper: {
			minWidth: 20,
			minHeight: 52,

			// height: 625,
			// height: 300,

			padding: 20,

			textAlign: 'left',

			background: 'radial-gradient(100% 97.73% at 0% 0%, hsl(192deg 13% 36% / 95%) 0%, hsl(191deg 19% 33% / 95%) 51.56%, rgba(52, 75, 80, 0.95) 100%)',
			// background: 'radial-gradient(100% 97.73% at 0% 0%, rgba(117, 142, 148, 0.95) 0%, rgba(73, 101, 107, 0.95) 51.56%, rgba(52, 75, 80, 0.95) 100%)',
			// background: 'radial-gradient(100% 97.73% at 0% 0%, rgba(117, 142, 148, 0.95) 0%, rgba(73, 101, 107, 0.95) 51.56%, rgba(52, 75, 80, 0.95) 100%)',
			boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.33)',
		},
		paneTitle: {
			width: 250,

			'& h3': {
				margin: 0,

				fontFamily: ThemeData.fonts.gothamMedium,
				fontSize: '24px',
				lineHeight: '32px',
				fontWeight: '350',
				letterSpacing: '.05em',

				textTransform: 'uppercase',
			},
		},
		viewTypeButtons: {

		},
		typeTitle: {
			textTransform: 'uppercase',
		},
		filterBy: {

			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: 16,
			letterSpacing: '.05em',

			'& input': {
				display: 'inline-block',

				margin: 10,
			},
		},
		filterActivated: {
			background: ThemeData.colours.clay,
		},
		filterSection: {
			marginTop: 30,
		},
		filterButtons: {
			display: 'flex',
			flexWrap: 'wrap',

			'& > div': {
				flex: '0 1 auto',
			},
		},
		filterItem: {
			minWidth: 30,
			height: 50,

			margin: 5,
			padding: '0 10px',

			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			// background: 'linear-gradient(0deg, #CE9065, #CE9065), #FFFFFF',

			border: `1px ${ThemeData.colours.eggshellTextBg} solid`,
			borderRadius: 100,

			transition: 'background .25s ease-out',

			cursor: 'pointer',
		},
		enjoyViewButtonWrapper: {
			display: 'table',
		},
		unitFloors: {

		},
		unitHomeTypes: {

		},
		unitPlanTypes: {

		},
	},
	{
		name: "UnitFilter",
	}
);


// function cr( data ) {
// 	if ( data )
// 		return data;

// 	return "";
// }

let filterList = {};

let intsTo100 = [];
for ( let i = 1; i <= 100; i++ )
	intsTo100.push( i );

function callProximaFilterFunction( unitIdArray, doShowOutline ) {
	if( window.pc )
		window.pc.Application.getApplication().buildingManager.filterCategory( unitIdArray, doShowOutline );
}
function turnOffAllFilteredUnitsWebGL() {
	callProximaFilterFunction( intsTo100, false );
}


function toggleFilter( filterTypeName, filterTypeItemId, elm, data, classes ) {
	// console.log( 'tn', filterTypeName, 'tiid', filterTypeItemId );

	// console.log( elm );

	// Always clear units shown
	turnOffAllFilteredUnitsWebGL();

	// Clear selected unit on new filter
	if ( clearSelectedUnitFcn )
		clearSelectedUnitFcn();

	if ( filterList[filterTypeName] && filterList[filterTypeName][filterTypeItemId] ) {
		// console.log( "HERE" );
		// Filter is already showing, turn off
		// callProximaFilterFunction( filterList[filterTypeName][filterTypeItemId], false );
		// turnOffAllFilteredUnitsWebGL();

		delete filterList[filterTypeName][filterTypeItemId];
		if ( Object.keys( filterList[filterTypeName] ).length === 0 )
			delete filterList[filterTypeName];

		elm.classList.remove( classes.filterActivated );
	} else {

		elm.classList.add( classes.filterActivated );

		const filterTypeItemsMatching = data[filterTypeName].find( ( filterTypeItem ) => filterTypeItem.id === filterTypeItemId );
		const unitsMatchingArray = filterTypeItemsMatching.units.map( unit => parseInt( unit.id ) );

		// console.log( unitsMatchingArray );

		if ( !filterList[filterTypeName] )
			filterList[filterTypeName] = {};
		filterList[filterTypeName] = {
			...filterList[filterTypeName],
			[filterTypeItemId]: unitsMatchingArray,
		};
	}

	// console.log( "**New Filter**")
	// console.log( filterList );

	let orUnitsToHighlight = [];
	// OR'ing each of the filterTypes together
	Object.keys( filterList ).forEach( thisFilterTypeName => 
		Object.keys( filterList[thisFilterTypeName] ).forEach( thisFilterTypeItemId =>
			orUnitsToHighlight.push(...filterList[thisFilterTypeName][thisFilterTypeItemId] ) ) );
	
	let unitsToHighlight = [];
	// AND'ing each of the filterTypes together
	let filterTypeKeys = Object.keys( filterList );
	if ( filterTypeKeys.length > 0 ) {
		// Put first filter straight in to final list
		Object.keys( filterList[filterTypeKeys[0]] ).forEach( thisFilterTypeItemId =>
			unitsToHighlight.push( ...filterList[filterTypeKeys[0]][thisFilterTypeItemId] ) )

		// console.log( unitsToHighlight );

		// Recursively intersect the final list with all subsequent filters
		for ( let i = 1; i < filterTypeKeys.length; i++ ) {
			let unitsToIntersect = [];
			Object.keys( filterList[filterTypeKeys[i]] ).forEach( thisFilterTypeItemId =>
				unitsToIntersect.push( ...filterList[filterTypeKeys[i]][thisFilterTypeItemId] ) )

			// console.log( unitsToIntersect );

			unitsToHighlight = unitsToHighlight.filter( unit => unitsToIntersect.includes( unit ) );

			// console.log( "filtered" );
			// console.log( unitsToHighlight );
		}
	}
	
	// console.log( orUnitsToHighlight );
	// console.log( unitsToHighlight );

	// callProximaFilterFunction( orUnitsToHighlight, true );
	callProximaFilterFunction( unitsToHighlight, true );
	// if( )
	// let app = pc.Application.getApplication();
	// window.pc.Application.getApplication().buildingManager.filterCategory( unitsMatching, true );
	// console.log( window );
}


let currentWebglViewType;
let setCurrentWebglViewType;

function callProximaFloorplateViewType( viewType ) {

	setCurrentWebglViewType( viewType );
	let webglViewArgument;
	switch ( viewType ) {
		case ConstantData.webglViewTypes.byFloorplateDetailed:
		case ConstantData.webglViewTypes.byFloorplateSimple:
			
			webglViewArgument = 1;
			break;
		case ConstantData.webglViewTypes.buliding3DModel:
		default:
			webglViewArgument = -1;
			break;
	}
	if ( window.pc )
		window.pc.Application.getApplication().floorPlanManager.setFloorPlanEnabled( webglViewArgument );
}
function setViewType( elm ) {
	console.log( elm );
	console.log( elm.target.value );

	callProximaFloorplateViewType( elm.target.value );
}


function UnitFilterRender( classes, data, error, props ) {

	const [doShowFilter, setDoShowFilter] = useState( true );
	// setTimeout( () => setDoShowFilter( !doShowFilter ), 4000 );

	return (
		<motion.div className={classes.borderWrapper}
			animate={{
				width: doShowFilter ? '' : '95px',
				height: doShowFilter ? '' : '120px',

				display: 'none',
			}}
			transition={{
				type: "tween",
				ease: "easeOut",
				duration: .33,
			}}
		>
			<div className={classes.wrapper}>
				<motion.div
					className={`${classes.expandRetractButtonWrapper}`}
					animate={{
						rotate: doShowFilter ? 180 : 0,
					}}
					transition={{
						duration: .33,
						ease: "easeOut",
					}}
					whileHover={{ scale: 1.1 }}
					whileTap={{ scale: 0.9 }}
				>
					<ButtonOutline
						padding={0}
						border="none"
						onClick={() => setDoShowFilter( !doShowFilter )}
					>
						<div className={`${classes.expandRetractButton} ${!doShowFilter && "isToExpand"}`} />
					</ButtonOutline>
				</motion.div>
				{!doShowFilter &&
					<motion.div
						className={classes.filterTitle}
						animate={{
							opacity: [0, 0, 1],
						}}
						exit={{
							opacity: 0,
						}}
					>
						<h4>Filter</h4>
					</motion.div>}
				<AnimatePresence>
					{doShowFilter && (
						<motion.div className={classes.showHideWrapper}
							initial={{
								originX: 0,
								originY: 0,
								scale: 0,
							}}
							animate={{
								originX: 0,
								originY: 0,
								scale: 1,
							}}
							exit={{
								originX: 0,
								originY: 0,
								scale: 0,
							}}
							transition={{
								type: "tween",
								ease: "easeOut",
							}}
						>
							<div className={classes.paneTitle} >
								<h3>Find Your Perfect Home</h3>
							</div>
							<div className={`${classes.filterSection} ${classes.filterBy}`}>
								<div className={classes.typeTitle} >
									<p>BY</p>
								</div>
								<form className={classes.viewTypeButtons} onChange={evt => setViewType( evt )} >
									<input type="radio"
										id={ConstantData.webglViewTypes.byFloorplateDetailed}
										name="viewBy"
										value={ConstantData.webglViewTypes.byFloorplateDetailed}
										checked={currentWebglViewType === ConstantData.webglViewTypes.byFloorplateDetailed}
									/>
									<label htmlFor={ConstantData.webglViewTypes.byFloorplateDetailed}>Floorplate (Detailed)</label><br />
									<input type="radio"
										id={ConstantData.webglViewTypes.byFloorplateSimple}
										name="viewBy"
										value={ConstantData.webglViewTypes.byFloorplateSimple}
										checked={currentWebglViewType === ConstantData.webglViewTypes.byFloorplateSimple}
									/>
									<label htmlFor={ConstantData.webglViewTypes.byFloorplateSimple}>Floorplate (Simple)</label><br />
									{/* <input type="radio" id="building3DModel" name="viewBy" value="building3DModel" onClick={evt => setViewType( evt.currentTarget )} checked /> */}
									<input type="radio"
										id={ConstantData.webglViewTypes.building3DModel}
										name="viewBy"
										value={ConstantData.webglViewTypes.building3DModel}
										checked={currentWebglViewType === ConstantData.webglViewTypes.building3DModel}
									/>
									<label htmlFor={ConstantData.webglViewTypes.building3DModel}>Building 3D Model</label><br />
								</form>
							</div>
							<div className={`${classes.filterSection} ${classes.floorTypeWrapper}`}>
								<div className={classes.typeTitle}>
									<p>SELECT FLOOR</p>
								</div>
								<div className={classes.filterButtons}>
									{data && data.unitFloors && data.unitFloors.map( ( floor ) => (
										<div className={`${classes.filterItem} ${classes.unitFloors}`} key={floor.id} typeitemid={floor.id} onClick={( evt ) => toggleFilter( "unitFloors", floor.id, evt.currentTarget, data, classes )}>
											<p>{floor.number}</p>
										</div>
									) )}
								</div>
							</div>
							{/* <div className={classes.enjoyViewButtonWrapper}>
								<ButtonOutline
									label="Enjoy the view"
									marginTop={15}
									padding={25}
									fontSize={14}
									textColour={ThemeData.colours.eggshellTextBg}
									hoverTextColour={ThemeData.colours.green}
									// backgroundColour="clear"
									borderColour={ThemeData.colours.white50}
									to="."
								/>
							</div> */}
							<div className={`${classes.filterSection} ${classes.homeTypeWrapper}`}>
								<div className={classes.typeTitle}>
									<p>ROOMS</p>
								</div>
								<div className={classes.filterButtons}>
									{data && data.unitHomeTypes && data.unitHomeTypes.map( ( homeType ) => (
										<div className={`${classes.filterItem} ${classes.unitHomeTypes}`} key={homeType.id} typeitemid={homeType.id} onClick={( evt ) => toggleFilter( "unitHomeTypes", homeType.id, evt.currentTarget, data, classes )}>
											<p>{homeType.name.toUpperCase()}</p>
										</div>
									) )}
								</div>
							</div>
							<div className={`${classes.filterSection} ${classes.orientationTypeWrapper}`}>
								<div className={classes.typeTitle}>
									<p>ORIENTATION</p>
								</div>
								<div className={classes.filterButtons}>
									{data && data.unitExposures && data.unitExposures.map( ( exposure ) => (
										<div className={`${classes.filterItem} ${classes.unitExposures}`} key={exposure.id} typeitemid={exposure.id} onClick={( evt ) => toggleFilter( "unitExposures", exposure.id, evt.currentTarget, data, classes )}>
											<p>{exposure.directionAbbrev}</p>
										</div>
									) )}
								</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</motion.div>
	);
}

let clearSelectedUnitFcn;


function UnitFilter( { openInWebglViewType, ...props }) {

	[currentWebglViewType, setCurrentWebglViewType] = useState( ConstantData.webglViewTypes.building3DModel );

	clearSelectedUnitFcn = props.clearSelectedUnitFcn;

	const classes = useStyles();
	const { loading, error, data } = useQuery( unitPropertiesQuery );

	if ( loading ) return UnitFilterRender( classes, null, null, props );
	if ( error ) return UnitFilterRender( classes, null, error, props );
	
	// console.log( data );

	return UnitFilterRender( classes, data, null, props );

}



export default UnitFilter;