const ConstantData = {
	contentCardForms: {
		oneUp: "oneUp",
		twoUpHoriz: "twoUpHoriz",
		twoUpVert: "twoUpVert",
		fourUp: "fourUp",
		sixUp: "sixUp",
	},
	contentPageCustomFunctions: {
		interactiveMap: "interactiveMap",
		photoGallery: "photoGallery",
	},
	sectionCustomFunctions: {
		proxima: "proxima",
		floorplans: "floorplans",
		gallery: "gallery",
	},
	webglViewTypes: {
		byFloorplateDetailed: "byFloorplateDetailed",
		byFloorplateSimple: "byFloorplateSimple",
		building3DModel: "building3DModel",
	},
	webglWindowMessageTypes: {
		webglHotspotClicked: "webglHotspotClicked",
	},
	localStorageKeys: {
		savedHomes: "savedHomes",
		registration: "registration",
	},
};

export default ConstantData;