let ThemeData = {
	colours: {
		clay: "#4B656A",
		clay25: "rgb( 75, 101, 106, .25 )",
		clay50: "rgb( 75, 101, 106, .50 )",
		clay75: "rgb( 75, 101, 106, .75 )",
		clay08: "rgb( 75, 101, 106, .08 )",
		// clay25: "rgba(206, 144, 101, .25)",
		// clay50: "rgba(206, 144, 101, .5)",
		// clay75: "rgba(206, 144, 101, .75)",
		// clay08: "rgba(206, 144, 101, .08)",
		// eggshellTextBg: "#EEEDE6",
		eggshellTextBg: "#000000",
		// goldText: "#DDAE5E",
		goldText: "#4B656A",
		// green: "#49656B",
		green: "rgb( 163, 208, 211 )",
		grey: "#434343",
		grey25: "rgba( 67, 67, 67, .25 )",
		white75: "rgb( 255, 255, 255, .75 )",
		white50: "rgb( 255, 255, 255, .4 )",
		white25: "rgb( 255, 255, 255, .25 )",
		white: "white",
		black: "rgba( 0, 0, 0, 1 )",
		black75: "rgba( 0, 0, 0, .75 )",
		blackBg40: "rgba( 0, 0, 0, .4 )",
		blackBg50: "rgba( 0, 0, 0, .5 )",
		blackBg5: "rgba( 0, 0, 0, .05 )",

	},
	fonts: {
		gothamMedium: "Gotham Medium, sans-serif",
		gothamBold: "Gotham Bold, sans-serif",
		gothamBook: "Gotham Book, sans-serif",
	},
}

// const opacitiesToCreate = {
// 	25: .25,
// 	50: .5,
// 	75: .75,
// 	08: .08,
// }

// ThemeData.colours = {
// 	...ThemeData.colours,
// 	opacitiesToCreate.map( opacityObj => )
// }

ThemeData.colours = {
	...ThemeData.colours,
	paneBorders: `1px ${ThemeData.colours.white} solid`,
}

ThemeData.typography = {
	nameplate: {
		color: ThemeData.colours.eggshellTextBg,
		fontFamily: ThemeData.fonts.gothamBold,
		textTransform: 'uppercase',
	},
	nameplateNoColor: {
		fontFamily: ThemeData.fonts.gothamBold,
		letterSpacing: '.15em',
		textTransform: 'uppercase',
	},
}

ThemeData.ux = {
	fillToLeft: {
		transition: 'all 0.66s ease',

		background: `linear-gradient( to right, rgb( 0, 0, 0, 0 ) 50%, ${ThemeData.colours.green} 50% )`,
		backgroundSize: '200% 100%',
		backgroundPosition: 'left bottom',

		'&:hover': {
			backgroundPosition: 'right bottom',
		},
	},
	fillToRight: {
		transition: 'all 0.66s ease',

		background: `linear-gradient( to left, rgb( 0, 0, 0, 0 ) 50%, ${ThemeData.colours.green} 50% )`,
		backgroundSize: '200% 100%',
		backgroundPosition: 'right bottom',

		'&:hover': {
			backgroundPosition: 'left bottom',
		},
	},
};


ThemeData.styles = {
	hoverCard: {
		color: ThemeData.colours.black,
		backgroundColor: 'white',
		// backgroundColor: ThemeData.colours.green,

		// border: `1px solid ${ThemeData.colours.eggshellTextBg}`,
		// boxShadow: '0px 4px 10px rgb(0 0 0 / 33%)',
		// borderRadius: 4,
	},
	hideScrollbar: {
		// Hide scrollbar
		'-ms-overflow-style': 'none',
		scrollbarWidth: 'none',

		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
}





export default ThemeData;
