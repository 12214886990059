import React from "react";

import {
	createUseStyles,
} from "react-jss";



import ThemeData from "../data/ThemeData";



import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";



const useStyles = createUseStyles(
	{
		header: props => ({
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: 100,

			backgroundColor: props.backgroundColor,

			zIndex: 2,
		}),
		backButton: {
			width: 100,
			height: 100,

			cursor: 'pointer',

			color: '#FFF',

			background: 'no-repeat center',
			backgroundImage: `url( ${navArrowLeftDark} )`,
		},
	},
	{
		name: "HeaderSimple",
	}
)



const HeaderSimple = ( { onBackClick, ...props } ) => {

	const classes = useStyles( props );

	return (
		<div className={classes.header}>
			<div className={classes.backButton} onClick={onBackClick} />
		</div>
	);
}

HeaderSimple.defaultProps = {
	backgroundColor: ThemeData.colours.white50,
};



export default HeaderSimple;