import React, {
	useState,
	useCallback,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
	gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";

import PhotoGallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";


import ThemeData from "data/ThemeData";
import QueryData from "data/QueryData";


import devLogo from "img/kindred_logo_darkFill_small.png";



const useStyles = createUseStyles(
	{
		wrapper: {
			boxSizing: 'border-box',
			width: '100%',
			height: 'calc( 100vh - 200px )',

			padding: 20,

			textAlign: 'left',

			overflowY: 'auto',

			...ThemeData.styles.hideScrollbar,
		},
	},
	{
		name: "Gallery",
	}
)



export const photos = [
	{
		src: "https://placekitten.com/300/400",
		width: 3,
		height: 4
	},
	{
		src: "https://placekitten.com/800/800",
		width: 1,
		height: 1
	},
	{
		src: "https://placekitten.com/300/400",
		width: 3,
		height: 4
	},
	{
		src: "https://placekitten.com/400/300",
		width: 4,
		height: 3
	},
	{
		src: "https://placekitten.com/800/800",
		width: 1,
		height: 1
	},
	{
		src: "https://placekitten.com/400/300",
		width: 4,
		height: 3
	},
];



const RenderGallery = ( data, error, classes, props ) => {

	if ( error ) {
		console.log( "Gallery Error:", error.message );
	}


	const [currentImage, setCurrentImage] = useState( 0 );
	const [isViewerOpen, setIsViewerOpen] = useState( false );

	const openLightbox = useCallback( ( e, { photo, index } ) => {
		// console.log( 'e', e, 'photo', photo, 'index', index );
		setCurrentImage( index );
		setIsViewerOpen( true );
	} );

	const closeLightbox = () => {
		setCurrentImage( 0 );
		setIsViewerOpen( false );
	};


	let galleryPhotos = [
		{
			src: devLogo,
			width: 400,
			height: 200,
		}
	]

	if ( data ) {
		galleryPhotos = data.galleryPhotos.map( photo => {
			return {
				src: photo.image.url,
				width: photo.image.width,
				height: photo.image.height,
			};
		} )
	};

	// console.log( 'galleryPhotos', galleryPhotos );

	return (
		<div className={`${classes.wrapper} ${props.className}`}>
			<PhotoGallery photos={galleryPhotos} onClick={openLightbox} />
			<ModalGateway>
				{isViewerOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
							currentIndex={currentImage}
							views={
								galleryPhotos.map( x => ( {
									...x,
									key: x.src,
									srcset: x.srcset,
									caption: x.title,
								} ) )
							}
						/>
					</Modal>
				) : null}
			</ModalGateway>
		</div>
	);
};



const Gallery = ( { ...props } ) => {

	const classes = useStyles();


	const { loading, error, data } = useQuery( QueryData.galleryPhotos );

	if ( loading ) return RenderGallery( null, null, classes, props );
	if ( error ) return RenderGallery( null, error, classes, props );


	// console.log( data );


	return RenderGallery( data, null, classes, props );

}



export default Gallery;














// import React, { useState, useCallback } from "react";
// import { render } from "react-dom";
// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";
// import { photos } from "./photos";

// function App() {
// 	const [currentImage, setCurrentImage] = useState( 0 );
// 	const [viewerIsOpen, setViewerIsOpen] = useState( false );

// 	const openLightbox = useCallback( ( event, { photo, index } ) => {
// 		setCurrentImage( index );
// 		setViewerIsOpen( true );
// 	}, [] );

// 	const closeLightbox = () => {
// 		setCurrentImage( 0 );
// 		setViewerIsOpen( false );
// 	};

// 	return (
// 		<div>
// 			<Gallery photos={photos} onClick={openLightbox} />
// 			<ModalGateway>
// 				{viewerIsOpen ? (
// 					<Modal onClose={closeLightbox}>
// 						<Carousel
// 							currentIndex={currentImage}
// 							views={photos.map( x => ( {
// 								...x,
// 								srcset: x.srcSet,
// 								caption: x.title
// 							} ) )}
// 						/>
// 					</Modal>
// 				) : null}
// 			</ModalGateway>
// 		</div>
// 	);
// }
// render( <App />, document.getElementById( "app" ) );