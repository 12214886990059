import {
	gql,
} from "@apollo/client";



const QueryData = {
	unitById: gql`
		query units( $unitId: ID! ) {
			unit (id: $unitId) {
				id
				name
				unit_number
				interior_sqft
				exterior_sqft
				baths
				is_typical_plan
				info_line_override
				floorplan_image {
					url
					alternativeText
					formats
				}
				keyplan_image {
					url
					alternativeText
					formats
				}
				unit_floor {
					id
					name
					number
				}
				unit_building {
					id
					name
				}
				unit_home_type {
					name
					number_beds
					text_beds
				}
				unit_plan_type {
					id
					name
					floorplan_image {
						url
						alternativeText
						formats
					}
					keyplan_image {
						url
						alternativeText
						formats
					}
				}
				unit_exposures {
					direction
				}
			}
		}
	`,
	units: gql`
		query units {
			units {
				id
				name
				unit_number
				interior_sqft
				exterior_sqft
				baths
				is_typical_plan
				info_line_override
				floorplan_image {
					url
					alternativeText
					formats
				}
				keyplan_image {
					url
					alternativeText
					formats
				}
				unit_floor {
					id
					name
					number
				}
				unit_building {
					id
					name
				}
				unit_home_type {
					id
					name
					number_beds
					text_beds
				}
				unit_plan_type {
					id
					name
					floorplan_image {
						url
						alternativeText
						formats
					}
					keyplan_image {
						url
						alternativeText
						formats
					}
				}
				unit_exposures {
					id
					direction
				}
			}
		}
	`,
	neighbourhoodLocations: gql`
		query neighbourhoodLocations {
			neighbourhoodLocations {
				id
				number
				name
				latitude
				longitude
				street_address
				city
				province_short
				postal_code
				neighbourhood_location_category {
					  name
					  colour
					  is_colour_outline
					  do_show_in_list
				}
			}
		}
	`,
	neighbourhoodLocationCategories: gql`
		query neighbourhoodLocationCategories {
			neighbourhoodLocationCategories( where: {
				do_show_in_list: "true"
			} ) {
				id
				name
				colour
				is_colour_outline
				do_show_in_list
				neighbourhood_locations {
					id
					number
					name
					latitude
					longitude
					street_address
					city
					province_short
					postal_code
				}
			}
		}
	`,
	galleryPhotos: gql`
		query galleryPhotos {
			galleryPhotos {
				name
				image {
					url
					alternativeText
					width
					height
					formats
				}
			}
		}
	`,
};



export default QueryData;