import React, {
	useEffect,
	useState,
	// useRef,
} from "react";

import { useQuery, gql } from "@apollo/client";

import { createUseStyles } from "react-jss";

import {
	Link,
	useParams,
	useRouteMatch,
} from "react-router-dom";

import {
	motion,
	// AnimatePresence,
} from 'framer-motion';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import { Scrollbar } from 'react-scrollbars-custom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


import ConstantData from "../data/ConstantData";
import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";
import SlugData from "../data/SlugData";


// import ContentPagePageIndicator from "./ContentPagePageIndicator";
import Map from "components/Map";
import ContentPageNeighbourhoodPois from "./ContentPageNeighbourhoodPois";
import HeaderComponents from "./HeaderComponents";
import HeaderContextData from "data/HeaderContextData";


// import contentPageNavArrowLeft from "../img/content_page_nav_arrow_left.svg";
// import contentPageNavArrowRight from "../img/content_page_nav_arrow_right.svg";
import contentPageX from "../img/content_page_x.svg";
import contentPageXDark from "../img/content_page_x_dark.svg";




// jj@lng acct
mapboxgl.accessToken = 'pk.eyJ1IjoibG5nc3R1ZGlvcyIsImEiOiJja3I2anhhaHAyaThoMnBzNjFqbGQ3MmxhIn0.Spy65NSiUTz-vo1GJqg9hA';
// // Winprox acct
// mapboxgl.accessToken = 'pk.eyJ1Ijoid2lucHJveCIsImEiOiJja3JhcGYyZ2Uybzg0MnduMzI2cjBwbjZ3In0.obHW3Bg-39To4R4JStjB7Q';
;


const contentPageQuery = gql`
	query ContentPages( $slug: String! ) {
		contentPages( where: {
			slug: $slug
		} ) {
			id
			title
			slug
			content
			custom_function
			is_dark_x_on_light_bg
			section {
				name
				slug
				content_pages {
					order
					slug
				}
			}
			image {
				url
			}
		}
	}
`;



const useStyles = createUseStyles(
	{
		wrapper: {
			height: '100%',

			color: ThemeData.colours.black,
		},
		contentPage: {
			display: 'flex',
			flexDirection: 'row',

			height: '100%',
		},
		backButtonWrapper: {
			display: 'flex',
			alignItems: 'center',

		},
		backButton: {
			flex: '0 0 auto',

			paddingLeft: 40,

			color: ThemeData.colours.black,
			fontSize: 24,
		},
		contentPane: {
			width: 390,
			height: '100%',

			flex: '0 1 auto',

			display: 'flex',
			flexDirection: 'column',

			backgroundColor: ThemeData.colours.green,

			textAlign: 'left',

			'&.withMap': {
				width: 550,

				backgroundColor: ThemeData.colours.white,
			},
		},
		contentContainer: {
			flex: '0 1 auto',
			// height: 'calc( 100vh - 200px )',
			display: 'flex',
			flexDirection: 'column',

			padding: 40,
		},
		navContainer: {
			height: 150,

			flex: '0 1 auto',

			padding: '0 40px 30px 40px',
		},
		navArrow: {
			marginTop: 30,
		},
		navArrowLeft: {

		},
		navArrowRight: {
			marginLeft: 20,
		},
		navArrowDisabled: {
			opacity: .1,
		},
		rightPane: {
			flex: '1 1 auto',

			background: 'no-repeat center',
			backgroundSize: 'cover',
		},
		contentArea: {
			flex: '0 0 auto',
		},
		sectionName: {
			color: ThemeData.colours.goldText,

			fontFamily: ThemeData.fonts.gothamBold,
		},
		title: {
			// color: ThemeData.colours.green,
			color: ThemeData.colours.black,

			marginTop: '.5em',

			fontSize: '2.5em',
			letterSpacing: 0,
			lineHeight: '1.2em',
		},
		content: {
			height: 'calc( 100vh - 360px )',

			// color: ThemeData.colours.grey,

			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: '1.2em',
			letterSpacing: '0.0125em',
			lineHeight: '2em',
		},
		scrollY: {
			overflowY: 'scroll',
			
			...ThemeData.styles.hideScrollbar,
		},
		contentPageX: {
			position: 'absolute',
			right: 0,

			width: 150,
			height: 150,

			background: 'no-repeat center',
			backgroundImage: `url('${contentPageX}')`,

			filter: 'drop-shadow(2px 2px 2px rgb(0, 0, 0, .15))',

			"&.isDark": {
				backgroundImage: `url('${contentPageXDark}')`,
			},
		},
		mapPane: {
			display: 'none',

			'&.doShow': {
				display: 'block',
			},
		},
	},
	{
		name: "ContentPage",
	}
);



const animationVariants = {
	toLeft: {

	},
}



function RenderContentPage( data = null, classes, error = null ) {

}



function ContentPage( { ...props } ) {

	const classes = useStyles();
	const apiUri = NetData.apiUri;
	const { contentPageSlug } = useParams();
	const matchUri = useRouteMatch();


	const [highlightCatPoi, setHighlightCatPoi] = useState( {
		isHighActive: false,
		highCatId: -1,
		highPoiId: -1,
	} );
	const prevHighlightCatPoi = highlightCatPoi;

	useEffect( () => {
		// console.log( highlightCatPoi );
	}, [highlightCatPoi] );

	// useEffect( () => {
	// 	console.log( highlightCatPoi );
	// }, [highlightCatPoi] );

	const [procFlyTo, setProcFlyTo] = useState( {
		doProcFlyTo: false,
		poiid: -1,
		catid: -1,
		coordinates: -1,
	} );


	// const [doShowMapBox, setDoShowMapBox] = useState( false );

	// // MapBox Integ
	// const mapContainer = useRef( null );
	// const map = useRef( null );
	// const [lng, setLng] = useState( -123.117253 );
	// const [lat, setLat] = useState( 49.24880342 );
	// const [zoom, setZoom] = useState( 14 )


	// useEffect( () => {
	// 	if ( mapContainer && mapContainer.current ) {
	// 		if ( map.current ) return; // initialize map only once
	// 		map.current = new mapboxgl.Map( {
	// 			container: mapContainer.current,
	// 			// style: 'mapbox://styles/winprox/ckrapigl3244y17qqunee7lll',
	// 			style: 'mapbox://styles/lngstudios/cktek0w4f2dzb17p52yq6toyl',
	// 			// style: 'mapbox://styles/mapbox/streets-v11',
	// 			center: [lng, lat],
	// 			zoom: zoom
	// 		} );
	// 	}
	// } );

	// useEffect( () => {
	// 	if ( !map.current ) return; // wait for map to initialize
	// 	map.current.on( 'move', () => {
	// 		setLng( map.current.getCenter().lng.toFixed( 4 ) );
	// 		setLat( map.current.getCenter().lat.toFixed( 4 ) );
	// 		setZoom( map.current.getZoom().toFixed( 2 ) );
	// 	} );
	// } );

	let doRenderInteractiveMap = false;

	const { loading, error, data } = useQuery( contentPageQuery, {
		variables: {
			slug: contentPageSlug,
		}
	} );

	if ( loading ) {
		return (
			<div>
				<p></p>
			</div>
		)
	};
	if ( error ) {
		return (
			<>
				<p>{console.log( 'cpa', error.message )}</p>
			</>
		);
	};

	// console.log( data );

	let contentPage = data.contentPages[0];

	let allContentPagesSection = contentPage.section.content_pages;
	allContentPagesSection = allContentPagesSection.slice().sort( ( pageA, pageB ) => pageA.order - pageB.order );
	// console.log( allContentPagesSection );
	let contentPageIdx = allContentPagesSection.findIndex( ( contentPage ) => contentPage.slug === contentPageSlug );
	// console.log( contentPageIdx );
	let prevContentPageIdx =
		contentPageIdx === 0 ?
			null :
			contentPageIdx - 1;
	let nextContentPageIdx =
		contentPageIdx === allContentPagesSection.length - 1 ?
			null :
			contentPageIdx + 1;
	// console.log( "curr:", contentPageIdx, "prevIdx:", prevContentPageIdx, "nextIdx:", nextContentPageIdx );


	doRenderInteractiveMap = contentPage.custom_function === ConstantData.contentPageCustomFunctions.interactiveMap;

	return (
		// <AnimatePresence>
		<motion.div
			className={classes.wrapper} id={contentPage.id}
			initial={{ y: '100vh' }}
			// initial={{ opacity: 0 }}
			animate={{ y: 0 }}
			// animate={{ opacity: 1 }}
			exit={{ y: '100vh' }}
			// transition={{ type: 'spring', bounce: .25, duration: .5 }}
			transition={{ type: 'tween', duration: .5 }}
		// exit={{ y: '100vh' }}
		>
			<HeaderComponents headerContext={HeaderContextData.contentPageHeaderContext}>
				<div className={classes.backButtonWrapper}>
					<Link
						to={`/${contentPage.section.slug}`}
					>
						<div
							className={classes.backButton}
						>
							<FontAwesomeIcon icon={faArrowLeft} /> BACK
						</div>
					</Link>
				</div>
				{/* <div
						// className={`${classes.mainMenuButton} ${classes.closeMainMenuButton}`}
					// onClick={() => toggleOpen( isOpen => !isOpen )}
					/>
					<div
						// className={`${classes.closeMainMenuText}`}
					// onClick={() => toggleOpen( isOpen => !isOpen )}
					>
						<p>Close Menu</p>
					</div> */}
			</HeaderComponents>
			<div
				className={classes.contentPage}
			>
				<div className={`${classes.contentPane} ${doRenderInteractiveMap ? "withMap" : ""}`}>
					<div className={classes.contentContainer}>
						<h4 className={`${classes.contentArea} ${classes.sectionName}`}>{contentPage.section.name.toUpperCase()}</h4>
						<h2 className={`${classes.contentArea} ${classes.title}`}>{contentPage.title.toUpperCase()}</h2>
						<div
							className={`${classes.contentArea} ${classes.content} ${doRenderInteractiveMap ? classes.scrollY : ""}`}
						// noDefaultStyles
						// style={{ width: 150, height: "100%", }}
						>
							{doRenderInteractiveMap ?
								<ContentPageNeighbourhoodPois
									highlightCatPoi={highlightCatPoi}
									setHighlightCatPoi={setHighlightCatPoi}
									setProcFlyTo={setProcFlyTo}
								/>
								:
								<p className={classes.content}>{contentPage.content}</p>
							}
						</div>
					</div>
					{/* <div className={classes.navContainer}>
							<ContentPagePageIndicator contentPageId={contentPage.id} />
							{prevContentPageIdx != null &&
								<Link to={`/${contentPage.section.slug}/${allContentPagesSection[prevContentPageIdx].slug}`} >
									<img className={`${classes.navArrow} ${classes.navArrowLeft}`} src={contentPageNavArrowLeft} alt="Content Page Nav Arrow Left" />
								</Link>
							}
							{prevContentPageIdx == null &&
								<img className={`${classes.navArrow} ${classes.navArrowLeft} ${classes.navArrowDisabled}`} src={contentPageNavArrowLeft} alt="Content Page Nav Arrow Left" />
							}
							{nextContentPageIdx != null &&
								<Link to={`/${contentPage.section.slug}/${allContentPagesSection[nextContentPageIdx].slug}`} >
									<img className={`${classes.navArrow} ${classes.navArrowRight}`} src={contentPageNavArrowRight} alt="Content Page Nav Arrow Right" />
								</Link>
							}
							{nextContentPageIdx == null &&
								<img className={`${classes.navArrow} ${classes.navArrowRight} ${classes.navArrowDisabled}`} src={contentPageNavArrowRight} alt="Content Page Nav Arrow Right" />
							}
						</div> */}
				</div>
				{/* <div ref={mapContainer} className={`${classes.rightPane} ${classes.map} ${doRenderInteractiveMap ? "doShow" : ""}`} /> */}
				<div className={`${classes.rightPane} ${classes.mapPane} ${doRenderInteractiveMap ? "doShow" : ""}`} >
					<Map
						doShow={doRenderInteractiveMap}
						highlightCatPoi={highlightCatPoi}
						setHighlightCatPoi={setHighlightCatPoi}
						procFlyTo={procFlyTo}
					/>
				</div>
				{doRenderInteractiveMap ?
					""
					:
					<div className={classes.rightPane} style={contentPage.image[0] && { backgroundImage: `url('${NetData.imgUri( contentPage.image[0].url )}')` }} />
				}

				{/* <Link to={`/${contentPage.section.slug}`}>
						<div className={`${classes.contentPageX} ${contentPage.is_dark_x_on_light_bg ? "isDark" : ""}`} />
					</Link> */}
			</div>
		</motion.div>
		// </AnimatePresence>
	);
}

export default ContentPage;