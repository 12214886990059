import createPersistedState from "use-persisted-state";

import ConstantData from "data/ConstantData";


const useSavedHomesState = createPersistedState( ConstantData.localStorageKeys.savedHomes );


const useSavedHomes = () => {

    const [savedHomes, setSavedHomes] = useSavedHomesState( [] );

    return {
        savedHomes,
        toggleSavedHome: unitId => {
            let prevUnitIdExistsIdx = savedHomes.findIndex(elm => elm.id == unitId);
            if (prevUnitIdExistsIdx === -1)
                setSavedHomes([...savedHomes, { id: unitId }]);
            else {
                let newSavedHomes = savedHomes;
                newSavedHomes.splice(prevUnitIdExistsIdx, 1);
                setSavedHomes([...newSavedHomes]);
            }
        }
    }

}


export default useSavedHomes;