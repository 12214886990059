import { useQuery, gql } from "@apollo/client";

import {
	Route,
	Switch,
	// Link,
	useRouteMatch,
	useParams,
} from "react-router-dom";

import {
	motion,
} from "framer-motion";

import { createUseStyles } from "react-jss";


import Layout from "./Layout";
import ContentPage from "./ContentPage";
import ContentCard from "./ContentCard";
import Floorplans from "./Floorplans";
import Gallery from "./Gallery";

// import { linear } from "popmotion";


import ThemeData from "../data/ThemeData";
import SlugData from "../data/SlugData";
import NetData from "../data/NetData";
import ConstantData from "data/ConstantData";


// import aboutBgHomes from "../img/about_bg_homes.png";
// import aboutBgHomes from process.env.REACT_APP_BACKEND_URI + '/uploads/about_bg_homes_1a78b46076.png';
// import sectionBg from "../img/overview_bg.png";
import overviewWatchVideoButton from "../img/overview_watch_video_button.svg";



const sectionQuery = gql`
	query Sections( $slug: String! ) {
		sections( where: {
			slug: $slug
		}) {
			name
			slug
			custom_override_function
			content_pages {
				id
				title
				slug
				form {
					name
					value
				}
				content
				image {
					url
					formats
				}
				is_only_image_card
				custom_link
				section {
					slug
				}
			}
		}
	}
`;

const useStyles = createUseStyles( {
	allWrapper: {
		height: '100%',
	},
	section: {
		height: '100%',

		display: 'flex',
		flexDirection: 'column',

		fontFamily: 'Gotham Medium',
		fontSize: '2em',
		letterSpacing: 0,
	},
	contentPage: {
		fontSize: 25,
	},
	marginSizer: {
		height: 80,

		flex: '0 1 auto',
	},
	contentCardsContainer: {
		flex: '1 1 auto',

		display: 'flex',
		justifyContent: 'space-between',

		margin: '0 60px',
	},
	contentCardContainer: {
		marginLeft: 20,

		'&.firstCardContainer': {
			marginLeft: 0,
		},
	},
	oneUpContainer: {
		flex: '1 1 auto',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	twoUpHorizContainer: {
		flex: '0 1 auto',
	},
	twoUpVertContainer: {
		flex: '1 1 auto',
	},
	fourUpContainer: {
		flex: '0 1 auto',
		width: 'calc( 66% - 20px )',
	},
	sixUpContainer: {
	},
	overview: {
		marginLeft: 100,

		// height: '100%',
		padding: 20,

		backgroundColor: ThemeData.colours.blackBg50,

		position: 'absolute',
		left: -20,
		bottom: 125,

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',

		textAlign: 'left',
	},
	overviewHeader: {
		flex: '0 1 auto',

		width: 500,

		fontFamily: ThemeData.fonts.gothamMedium,
		'& h1': {
			margin: '20px auto',

			fontSize: '2.5em',
			fontWeight: 'normal',
			lineHeight: '.95em',
		}
	},
	overviewDescription: {
		flex: '0 1 auto',

		width: 500,

		fontFamily: ThemeData.fonts.gothamBook,

		fontSize: '.6em',
		lineHeight: '1.3em',
	},
	overviewWatchVideoButton: {
		flex: '0 1 auto',

		// width: 205,
		width: 500,
		height: 60,

		background: 'no-repeat',
		backgroundImage: `url(${overviewWatchVideoButton})`,

		cursor: 'pointer',
	},
	mapboxOpen: {
		width: 25,
		height: 25,

		cursor: 'pointer',
	},
} );

function Section( { sectionSlug } ) {

	let { slug } = useParams();

	if ( sectionSlug )
		slug = sectionSlug;
	// console.log( slug );

	const classes = useStyles();
	const apiUri = NetData.apiUri;
	const matchUri = useRouteMatch();

	let isFirstOneUpItem;

	// console.log( slug );

	const { loading, error, data } = useQuery( sectionQuery, {
		variables: {
			slug,
		}
	} );

	if ( slug === "404.html" ) return (
		<div>
			<h2>404 Error</h2>
			<p>The page you are trying to reach is not here.</p>
		</div>
	);

	if ( loading ) return <p></p>
	if ( error ) return <p>{console.log( 's', error.message )}</p>

	// console.log( "section", data );

	let firstOneUpContentPage;
	let isFirstCardContainer = true;

	let thisSection = data.sections[0];


	return (
		<Switch>
			<Route path="404.html">
				<div>
					<h2>404 Error</h2>
					<p>The page you are trying to reach is not here.</p>
				</div>
			</Route>
			<Route exact path={matchUri.path}>
				<Layout>
					<motion.section
						className={classes.section}
						exit={{ opacity: 0 }}
						transition={{ duration: .25 }}
					>
						{data && data.sections && thisSection && thisSection.custom_override_function &&
							thisSection.custom_override_function === ConstantData.sectionCustomFunctions.floorplans &&
							<>
								<Floorplans />
							</>
						}
						{data && data.sections && thisSection && thisSection.custom_override_function &&
							thisSection.custom_override_function === ConstantData.sectionCustomFunctions.gallery &&
							<>
								<Gallery />
							</>
						}
						{data && data.sections && thisSection && !thisSection.custom_override_function &&
							<>
								<div className={classes.marginSizer} />
								{sectionSlug === SlugData.HOME_SLUG_NAME ?
									<>
										{/* <div className={classes.overview}>
									<div className={classes.overviewHeader}>
										<h1>{data.sections[0].content_pages[0].content}</h1>
									</div>
									<div className={classes.overviewDescription}>
										<p>{data.sections[0].content_pages[1].content}</p>
									</div>
									<div className={classes.overviewWatchVideoButton}>

									</div>
								</div> */}
									</>
									:
									<div className={classes.contentCardsContainer}>
										{data.sections[0].content_pages && data.sections[0].content_pages.map( ( contentPage ) => {
											if ( contentPage.form && contentPage.form.value === "oneUp" ) {
												if ( !firstOneUpContentPage ) {
													firstOneUpContentPage = contentPage;
													return null;
												}
												return (
													<div className={`${classes.contentCardContainer} ${classes.oneUpContainer} ${isFirstCardContainer ? "firstCardContainer" : ""}`} key={contentPage.id}>
														<ContentCard contentPage={firstOneUpContentPage} />
														<ContentCard contentPage={contentPage} />
														{firstOneUpContentPage = null}
														{isFirstCardContainer = false}
													</div>
												);
											}
											return (
												<div className={`${classes.contentCardContainer} ${classes[contentPage.form.value + "Container"]} ${isFirstCardContainer ? "firstCardContainer" : ""}`} key={contentPage.id}>
													<ContentCard contentPage={contentPage} key={contentPage.id} />
													{isFirstCardContainer = false}
												</div>
											);
										} )
										}
									</div>
								}
								{sectionSlug === "neighbourhood" &&
									<div
										className={classes.mapboxOpen}
									>
										+
									</div>
								}
								<div className={classes.marginSizer} />
							</>
						}
					</motion.section>
					{/* <MapBox /> */}
				</Layout>
			</Route>
			<Route path={`${matchUri.path}/:contentPageSlug`} >
				<ContentPage />
			</Route>
		</Switch>
	);
}

export default Section;