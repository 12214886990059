import React, {
	useState,
	useRef,
	useEffect,
} from "react";
import ReactDOM from 'react-dom';

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";


import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax


// import neighGeo from "data/neighbourhood";
import neighGeo from "data/kindred-neighbourhood";
import walkingGeo from "data/walkingIcons";

import ThemeData from "../data/ThemeData";
import QueryData from "data/QueryData";


import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
import mapMarkerDev from "img/map_marker_kindred.svg";
import mapIconWalking from "img/map_icon_walking.svg";


// jj@lng acct
mapboxgl.accessToken = 'pk.eyJ1IjoibG5nc3R1ZGlvcyIsImEiOiJja3I2anhhaHAyaThoMnBzNjFqbGQ3MmxhIn0.Spy65NSiUTz-vo1GJqg9hA';
// Winprox acct
// mapboxgl.accessToken = 'pk.eyJ1Ijoid2lucHJveCIsImEiOiJja3JhcGYyZ2Uybzg0MnduMzI2cjBwbjZ3In0.obHW3Bg-39To4R4JStjB7Q';



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: '100%',
			// height: 'calc( 100vh - 100px )',


		},
		map: {
			// backgroundColor: "#000000",

			width: "100%",
			height: 'calc( 100vh - 100px )',

			display: 'none',

			'&.doShow': {
				display: 'block',
			},
		},
		marker: {
			// position: 'relative',

			width: 40,
			height: 40,

			display: 'flex',
			alignItems: 'center',

			color: ThemeData.colours.white,

			fontSize: 18,
			textAlign: 'center',
			letterSpacing: '.025em',

			cursor: 'pointer',

			borderRadius: '50%',

			transition: 'transform .5s ease-out',

			"&:hover $markerTooltip": {
				opacity: 1,
			},

			'&.walkerMarker': {
				width: 70,
				height: 'auto',

				fontFamily: ThemeData.fonts.gothamBold,
				fontSize: 8,
				// textAlign: 'center',
				// letterSpacing: '.025em',
				textTransform: 'uppercase',

				borderRadius: 0,
			},
		},
		markerDisplay: {
			flex: '1 1 auto',

			borderRadius: '50%',

			transition: 'transform 1s',

			'&.walkerMarker': {
				lineHeight: '1.4em',
			},
		},
		'.markerTooltip': {
			position: 'absolute',

			opacity: 0,

			maxWidth: 200,

			textAlign: 'center',

			transition: 'all 2s, opacity .5s',

			// color: ThemeData.colours.green,
			// backgroundColor: 'white',
			backgroundColor: ThemeData.colours.green,

			border: `1px solid ${ThemeData.colours.eggshellTextBg}`,
			boxShadow: '0px 4px 10px rgb(0 0 0 / 33%)',
			borderRadius: 4,
		},
		markerPopup: {
			fontSize: 40,

			'& .mapboxgl-popup-tip': {
				borderTopColor: ThemeData.colours.green,
				marginTop: -1,
			},
			'& .mapboxgl-popup-content': {
				backgroundColor: ThemeData.colours.green,
				color: ThemeData.colours.black,
				fontSize: 16,

				'& h4': {
					margin: 0,
					fontSize: 14,
				},
				'& p': {
					margin: 0,
					fontSize: 15,
				},
			},
			'& .mapboxgl-popup-close-button': {
				color: ThemeData.colours.black,

				fontSize: 24,
			},
		},
		markerHighlight: {
			transform: 'scale( 1.5 )',

			zIndex: 4,
		},
		markerParentHighlight: {
			zIndex: 4,
		},
		dev: {
			width: 60,
			height: 75,

			borderRadius: 0,

			background: "no-repeat center",
			backgroundImage: `url(${mapMarkerDev})`,
		},
		walkingIcon: {
			width: 20,
		},
	},
	{
		name: "Map",
	}
)


const poiMapboxMarkers = [];

function RenderMap( classes, data, error, doShow, props ) {

	const [prevElms, setPrevElms] = useState( null );
	const [poiMapboxMarkers, setPoiMapboxMarkers] = useState( {} );

	useEffect( () => {
		// console.log( props.highlightCatPoi );
		// console.log( "prev", prevElms );
		let elms;

		if ( props.highlightCatPoi.highCatId != -1 ) {
			elms = document.querySelectorAll( `[catid='${props.highlightCatPoi.highCatId}']` );
		} else if ( props.highlightCatPoi.highPoiId != -1 ) {
			elms = document.querySelectorAll( `[poiid='${props.highlightCatPoi.highPoiId}']` );
		}
		// elms = document.querySelectorAll( )

		if ( prevElms ) {
			prevElms.forEach( elm => {
				elm.classList.remove( classes.markerHighlight );
				elm.parentElement.classList.remove( classes.markerParentHighlight );
			} )
			setPrevElms( null );
		}

		if ( elms ) {
			elms.forEach( elm => {
				elm.classList.add( classes.markerHighlight );
				elm.parentElement.classList.add( classes.markerParentHighlight );
			} )
		}

		setPrevElms( elms );
		// console.log( 'endelms', elms, 'endprev', prevElms );
	}, [props.highlightCatPoi] );


	// MapBox Integ
	const mapContainer = useRef( null );
	const map = useRef( null );
	const [lng, setLng] = useState( -79.7068042 );
	const [lat, setLat] = useState( 43.5593815 );
	const [zoom, setZoom] = useState( 15.4 );
	// const [lng, setLng] = useState( -123.118894 );
	// const [lat, setLat] = useState( 49.248694 );
	// const [zoom, setZoom] = useState( 14 )


	useEffect( () => {
		if ( mapContainer && mapContainer.current ) {
			if ( map.current ) return; // initialize map only once
			map.current = new mapboxgl.Map( {
				container: mapContainer.current,
				// style: 'mapbox://styles/winprox/ckrapigl3244y17qqunee7lll',
				style: 'mapbox://styles/lngstudios/cktek0w4f2dzb17p52yq6toyl',
				center: [lng, lat],
				zoom: zoom,
				pitch: 60,
				// dragRotate: false,
				attributionControl: false,
			} );

			// Add Compass control
			let compassControl = new mapboxgl.NavigationControl( {
				showCompass: true,
				showZoom: false,
			} );
			map.current.addControl( compassControl, "bottom-right" );


			/* #region Walking Distance Rings */
			// Add walking distance rings
			// map.current.on( "load", () => {
			// 	map.current.addSource( '5minwalk', {
			// 		'type': 'geojson',
			// 		'data': {
			// 			'type': 'Feature',
			// 			'geometry': {
			// 				'type': 'Polygon',
			// 				'coordinates': [
			// 					[
			// 						[
			// 							-123.10889399999999,
			// 							49.248694
			// 						],
			// 						[
			// 							-123.10890604543795,
			// 							49.24900067296449
			// 						],
			// 						[
			// 							-123.10894215273326,
			// 							49.24930660712695
			// 						],
			// 						[
			// 							-123.1090022349003,
			// 							49.249611065465174
			// 						],
			// 						[
			// 							-123.1090861471959,
			// 							49.249913314512376
			// 						],
			// 						[
			// 							-123.10919368746794,
			// 							49.25021262612412
			// 						],
			// 						[
			// 							-123.10932459664251,
			// 							49.25050827923251
			// 						],
			// 						[
			// 							-123.10947855934795,
			// 							49.25079956158332
			// 						],
			// 						[
			// 							-123.1096552046746,
			// 							49.251085771451855
			// 						],
			// 						[
			// 							-123.10985410706841,
			// 							49.25136621933347
			// 						],
			// 						[
			// 							-123.11007478735608,
			// 							49.251640229604654
			// 						],
			// 						[
			// 							-123.11031671389948,
			// 							49.251907142150664
			// 						],
			// 						[
			// 							-123.11057930387636,
			// 							49.2521663139558
			// 						],
			// 						[
			// 							-123.11086192468447,
			// 							49.252417120652474
			// 						],
			// 						[
			// 							-123.11116389546555,
			// 							49.2526589580254
			// 						],
			// 						[
			// 							-123.11148448874552,
			// 							49.252891243467154
			// 						],
			// 						[
			// 							-123.1118229321871,
			// 							49.25311341738176
			// 						],
			// 						[
			// 							-123.11217841045037,
			// 							49.253324944532814
			// 						],
			// 						[
			// 							-123.11255006715707,
			// 							49.25352531533286
			// 						],
			// 						[
			// 							-123.11293700695367,
			// 							49.2537140470711
			// 						],
			// 						[
			// 							-123.11333829766826,
			// 							49.25389068507625
			// 						],
			// 						[
			// 							-123.11375297255641,
			// 							49.25405480381188
			// 						],
			// 						[
			// 							-123.11418003262995,
			// 							49.25420600790158
			// 						],
			// 						[
			// 							-123.11461844906378,
			// 							49.25434393308146
			// 						],
			// 						[
			// 							-123.1150671656743,
			// 							49.25446824707767
			// 						],
			// 						[
			// 							-123.1155251014639,
			// 							49.25457865040691
			// 						],
			// 						[
			// 							-123.11599115322517,
			// 							49.25467487709789
			// 						],
			// 						[
			// 							-123.11646419819856,
			// 							49.25475669533209
			// 						],
			// 						[
			// 							-123.1169430967773,
			// 							49.25482390800221
			// 						],
			// 						[
			// 							-123.1174266952528,
			// 							49.254876353187036
			// 						],
			// 						[
			// 							-123.11791382859396,
			// 							49.25491390454153
			// 						],
			// 						[
			// 							-123.11840332325387,
			// 							49.2549364716012
			// 						],
			// 						[
			// 							-123.11889399999704,
			// 							49.254944
			// 						],
			// 						[
			// 							-123.11938467674024,
			// 							49.254936471601376
			// 						],
			// 						[
			// 							-123.11987417140017,
			// 							49.254913904541894
			// 						],
			// 						[
			// 							-123.12036130474137,
			// 							49.254876353187576
			// 						],
			// 						[
			// 							-123.1208449032169,
			// 							49.254823908002926
			// 						],
			// 						[
			// 							-123.12132380179573,
			// 							49.254756695332986
			// 						],
			// 						[
			// 							-123.12179684676919,
			// 							49.254674877098964
			// 						],
			// 						[
			// 							-123.12226289853054,
			// 							49.25457865040815
			// 						],
			// 						[
			// 							-123.12272083432025,
			// 							49.254468247079075
			// 						],
			// 						[
			// 							-123.12316955093088,
			// 							49.25434393308303
			// 						],
			// 						[
			// 							-123.12360796736485,
			// 							49.25420600790332
			// 						],
			// 						[
			// 							-123.12403502743854,
			// 							49.25405480381377
			// 						],
			// 						[
			// 							-123.12444970232683,
			// 							49.2538906850783
			// 						],
			// 						[
			// 							-123.12485099304159,
			// 							49.253714047073295
			// 						],
			// 						[
			// 							-123.12523793283836,
			// 							49.253525315335196
			// 						],
			// 						[
			// 							-123.12560958954526,
			// 							49.25332494453529
			// 						],
			// 						[
			// 							-123.12596506780874,
			// 							49.25311341738437
			// 						],
			// 						[
			// 							-123.12630351125051,
			// 							49.25289124346988
			// 						],
			// 						[
			// 							-123.1266241045307,
			// 							49.25265895802825
			// 						],
			// 						[
			// 							-123.126926075312,
			// 							49.25241712065544
			// 						],
			// 						[
			// 							-123.12720869612036,
			// 							49.25216631395886
			// 						],
			// 						[
			// 							-123.12747128609749,
			// 							49.251907142153826
			// 						],
			// 						[
			// 							-123.12771321264114,
			// 							49.25164022960791
			// 						],
			// 						[
			// 							-123.12793389292906,
			// 							49.2513662193368
			// 						],
			// 						[
			// 							-123.12813279532314,
			// 							49.251085771455266
			// 						],
			// 						[
			// 							-123.12830944065006,
			// 							49.250799561586796
			// 						],
			// 						[
			// 							-123.12846340335577,
			// 							49.25050827923604
			// 						],
			// 						[
			// 							-123.12859431253062,
			// 							49.25021262612769
			// 						],
			// 						[
			// 							-123.12870185280295,
			// 							49.24991331451599
			// 						],
			// 						[
			// 							-123.12878576509883,
			// 							49.24961106546882
			// 						],
			// 						[
			// 							-123.12884584726616,
			// 							49.24930660713061
			// 						],
			// 						[
			// 							-123.12888195456176,
			// 							49.24900067296817
			// 						],
			// 						[
			// 							-123.128894,
			// 							49.24869400000369
			// 						],
			// 						[
			// 							-123.12888195456235,
			// 							49.2483873270392
			// 						],
			// 						[
			// 							-123.12884584726731,
			// 							49.24808139287672
			// 						],
			// 						[
			// 							-123.12878576510055,
			// 							49.24777693453847
			// 						],
			// 						[
			// 							-123.12870185280525,
			// 							49.24747468549124
			// 						],
			// 						[
			// 							-123.1285943125335,
			// 							49.24717537387946
			// 						],
			// 						[
			// 							-123.12846340335919,
			// 							49.24687972077102
			// 						],
			// 						[
			// 							-123.12830944065404,
			// 							49.24658843842015
			// 						],
			// 						[
			// 							-123.12813279532764,
			// 							49.24630222855155
			// 						],
			// 						[
			// 							-123.12793389293411,
			// 							49.246021780669864
			// 						],
			// 						[
			// 							-123.1277132126467,
			// 							49.245747770398594
			// 						],
			// 						[
			// 							-123.12747128610356,
			// 							49.2454808578525
			// 						],
			// 						[
			// 							-123.12720869612691,
			// 							49.245221686047266
			// 						],
			// 						[
			// 							-123.12692607531903,
			// 							49.24497087935048
			// 						],
			// 						[
			// 							-123.12662410453818,
			// 							49.24472904197745
			// 						],
			// 						[
			// 							-123.12630351125844,
			// 							49.244496756535575
			// 						],
			// 						[
			// 							-123.12596506781708,
			// 							49.24427458262084
			// 						],
			// 						[
			// 							-123.125609589554,
			// 							49.24406305546967
			// 						],
			// 						[
			// 							-123.12523793284747,
			// 							49.24386268466948
			// 						],
			// 						[
			// 							-123.12485099305107,
			// 							49.2436739529311
			// 						],
			// 						[
			// 							-123.12444970233663,
			// 							49.2434973149258
			// 						],
			// 						[
			// 							-123.12403502744864,
			// 							49.243333196190015
			// 						],
			// 						[
			// 							-123.12360796737525,
			// 							49.243181992100155
			// 						],
			// 						[
			// 							-123.12316955094155,
			// 							49.24304406692012
			// 						],
			// 						[
			// 							-123.12272083433115,
			// 							49.24291975292375
			// 						],
			// 						[
			// 							-123.12226289854163,
			// 							49.24280934959433
			// 						],
			// 						[
			// 							-123.12179684678048,
			// 							49.242713122903176
			// 						],
			// 						[
			// 							-123.12132380180716,
			// 							49.242631304668805
			// 						],
			// 						[
			// 							-123.12084490322847,
			// 							49.24256409199852
			// 						],
			// 						[
			// 							-123.12036130475303,
			// 							49.242511646813504
			// 						],
			// 						[
			// 							-123.11987417141191,
			// 							49.24247409545883
			// 						],
			// 						[
			// 							-123.11938467675202,
			// 							49.24245152839899
			// 						],
			// 						[
			// 							-123.11889400000885,
			// 							49.242444
			// 						],
			// 						[
			// 							-123.11840332326565,
			// 							49.24245152839844
			// 						],
			// 						[
			// 							-123.1179138286057,
			// 							49.242474095457744
			// 						],
			// 						[
			// 							-123.11742669526447,
			// 							49.242511646811884
			// 						],
			// 						[
			// 							-123.11694309678887,
			// 							49.24256409199636
			// 						],
			// 						[
			// 							-123.11646419821,
			// 							49.24263130466612
			// 						],
			// 						[
			// 							-123.11599115323645,
			// 							49.24271312289997
			// 						],
			// 						[
			// 							-123.11552510147501,
			// 							49.24280934959061
			// 						],
			// 						[
			// 							-123.1150671656852,
			// 							49.24291975291951
			// 						],
			// 						[
			// 							-123.11461844907444,
			// 							49.243044066915395
			// 						],
			// 						[
			// 							-123.11418003264035,
			// 							49.24318199209495
			// 						],
			// 						[
			// 							-123.11375297256652,
			// 							49.24333319618433
			// 						],
			// 						[
			// 							-123.11333829767808,
			// 							49.24349731491965
			// 						],
			// 						[
			// 							-123.11293700696314,
			// 							49.2436739529245
			// 						],
			// 						[
			// 							-123.1125500671662,
			// 							49.24386268466247
			// 						],
			// 						[
			// 							-123.11217841045911,
			// 							49.24406305546224
			// 						],
			// 						[
			// 							-123.11182293219542,
			// 							49.24427458261302
			// 						],
			// 						[
			// 							-123.11148448875345,
			// 							49.24449675652738
			// 						],
			// 						[
			// 							-123.11116389547304,
			// 							49.2447290419689
			// 						],
			// 						[
			// 							-123.1108619246915,
			// 							49.2449708793416
			// 						],
			// 						[
			// 							-123.11057930388291,
			// 							49.24522168603807
			// 						],
			// 						[
			// 							-123.11031671390553,
			// 							49.24548085784301
			// 						],
			// 						[
			// 							-123.11007478736164,
			// 							49.245747770388846
			// 						],
			// 						[
			// 							-123.10985410707345,
			// 							49.246021780659866
			// 						],
			// 						[
			// 							-123.10965520467911,
			// 							49.24630222854133
			// 						],
			// 						[
			// 							-123.10947855935193,
			// 							49.24658843840974
			// 						],
			// 						[
			// 							-123.10932459664593,
			// 							49.24687972076043
			// 						],
			// 						[
			// 							-123.1091936874708,
			// 							49.247175373868735
			// 						],
			// 						[
			// 							-123.1090861471982,
			// 							49.2474746854804
			// 						],
			// 						[
			// 							-123.10900223490204,
			// 							49.247776934527536
			// 						],
			// 						[
			// 							-123.10894215273441,
			// 							49.24808139286572
			// 						],
			// 						[
			// 							-123.10890604543852,
			// 							49.24838732702815
			// 						]
			// 					]
			// 				]
			// 			}
			// 		}
			// 	} )

			// 	map.current.addSource( '10minwalk', {
			// 		'type': 'geojson',
			// 		'data': {
			// 			'type': 'Feature',
			// 			'geometry': {
			// 				'type': 'Polygon',
			// 				'coordinates': [
			// 					[
			// 						[
			// 							-123.097894,
			// 							49.248694
			// 						],
			// 						[
			// 							-123.09791929541969,
			// 							49.249338013225426
			// 						],
			// 						[
			// 							-123.09799512073984,
			// 							49.249980474966584
			// 						],
			// 						[
			// 							-123.09812129329065,
			// 							49.25061983747687
			// 						],
			// 						[
			// 							-123.09829750911138,
			// 							49.251254560475985
			// 						],
			// 						[
			// 							-123.09852334368267,
			// 							49.25188311486065
			// 						],
			// 						[
			// 							-123.09879825294928,
			// 							49.25250398638827
			// 						],
			// 						[
			// 							-123.0991215746307,
			// 							49.25311567932498
			// 						],
			// 						[
			// 							-123.09949252981667,
			// 							49.2537167200489
			// 						],
			// 						[
			// 							-123.09991022484365,
			// 							49.25430566060029
			// 						],
			// 						[
			// 							-123.10037365344778,
			// 							49.254881082169774
			// 						],
			// 						[
			// 							-123.1008816991889,
			// 							49.2554415985164
			// 						],
			// 						[
			// 							-123.10143313814035,
			// 							49.255985859307174
			// 						],
			// 						[
			// 							-123.10202664183741,
			// 							49.2565125533702
			// 						],
			// 						[
			// 							-123.10266078047766,
			// 							49.25702041185334
			// 						],
			// 						[
			// 							-123.10333402636559,
			// 							49.25750821128102
			// 						],
			// 						[
			// 							-123.10404475759289,
			// 							49.257974776501705
			// 						],
			// 						[
			// 							-123.10479126194578,
			// 							49.2584189835189
			// 						],
			// 						[
			// 							-123.10557174102986,
			// 							49.258839762199
			// 						],
			// 						[
			// 							-123.1063843146027,
			// 							49.259236098849314
			// 						],
			// 						[
			// 							-123.10722702510337,
			// 							49.25960703866013
			// 						],
			// 						[
			// 							-123.10809784236845,
			// 							49.259951688004946
			// 						],
			// 						[
			// 							-123.1089946685229,
			// 							49.260269216593315
			// 						],
			// 						[
			// 							-123.10991534303393,
			// 							49.260558859471054
			// 						],
			// 						[
			// 							-123.11085764791603,
			// 							49.2608199188631
			// 						],
			// 						[
			// 							-123.11181931307421,
			// 							49.26105176585451
			// 						],
			// 						[
			// 							-123.11279802177283,
			// 							49.26125384190557
			// 						],
			// 						[
			// 							-123.11379141621696,
			// 							49.26142566019738
			// 						],
			// 						[
			// 							-123.11479710323235,
			// 							49.26156680680463
			// 						],
			// 						[
			// 							-123.11581266003088,
			// 							49.26167694169278
			// 						],
			// 						[
			// 							-123.1168356400473,
			// 							49.26175579953722
			// 						],
			// 						[
			// 							-123.11786357883312,
			// 							49.26180319036251
			// 						],
			// 						[
			// 							-123.1188939999938,
			// 							49.261819
			// 						],
			// 						[
			// 							-123.1199244211545,
			// 							49.26180319036289
			// 						],
			// 						[
			// 							-123.12095235994038,
			// 							49.26175579953797
			// 						],
			// 						[
			// 							-123.12197533995686,
			// 							49.261676941693906
			// 						],
			// 						[
			// 							-123.1229908967555,
			// 							49.261566806806144
			// 						],
			// 						[
			// 							-123.12399658377102,
			// 							49.261425660199265
			// 						],
			// 						[
			// 							-123.12498997821531,
			// 							49.26125384190782
			// 						],
			// 						[
			// 							-123.12596868691413,
			// 							49.26105176585712
			// 						],
			// 						[
			// 							-123.1269303520725,
			// 							49.26081991886606
			// 						],
			// 						[
			// 							-123.12787265695486,
			// 							49.260558859474365
			// 						],
			// 						[
			// 							-123.12879333146617,
			// 							49.26026921659697
			// 						],
			// 						[
			// 							-123.12969015762091,
			// 							49.259951688008925
			// 						],
			// 						[
			// 							-123.13056097488632,
			// 							49.25960703866443
			// 						],
			// 						[
			// 							-123.13140368538734,
			// 							49.259236098853926
			// 						],
			// 						[
			// 							-123.13221625896055,
			// 							49.25883976220391
			// 						],
			// 						[
			// 							-123.13299673804505,
			// 							49.2584189835241
			// 						],
			// 						[
			// 							-123.13374324239835,
			// 							49.257974776507176
			// 						],
			// 						[
			// 							-123.13445397362608,
			// 							49.25750821128676
			// 						],
			// 						[
			// 							-123.13512721951447,
			// 							49.25702041185932
			// 						],
			// 						[
			// 							-123.13576135815521,
			// 							49.25651255337642
			// 						],
			// 						[
			// 							-123.13635486185277,
			// 							49.25598585931361
			// 						],
			// 						[
			// 							-123.13690630080472,
			// 							49.255441598523035
			// 						],
			// 						[
			// 							-123.1374143465464,
			// 							49.2548810821766
			// 						],
			// 						[
			// 							-123.13787777515104,
			// 							49.25430566060729
			// 						],
			// 						[
			// 							-123.13829547017859,
			// 							49.25371672005605
			// 						],
			// 						[
			// 							-123.13866642536513,
			// 							49.25311567933227
			// 						],
			// 						[
			// 							-123.13898974704712,
			// 							49.25250398639568
			// 						],
			// 						[
			// 							-123.13926465631431,
			// 							49.25188311486815
			// 						],
			// 						[
			// 							-123.1394904908862,
			// 							49.25125456048358
			// 						],
			// 						[
			// 							-123.13966670670753,
			// 							49.25061983748453
			// 						],
			// 						[
			// 							-123.13979287925893,
			// 							49.249980474974286
			// 						],
			// 						[
			// 							-123.13986870457971,
			// 							49.24933801323316
			// 						],
			// 						[
			// 							-123.139894,
			// 							49.24869400000774
			// 						],
			// 						[
			// 							-123.13986870458092,
			// 							49.248049986782306
			// 						],
			// 						[
			// 							-123.13979287926136,
			// 							49.24740752504112
			// 						],
			// 						[
			// 							-123.13966670671115,
			// 							49.24676816253079
			// 						],
			// 						[
			// 							-123.13949049089103,
			// 							49.246133439531604
			// 						],
			// 						[
			// 							-123.13926465632032,
			// 							49.245504885146865
			// 						],
			// 						[
			// 							-123.13898974705431,
			// 							49.244884013619135
			// 						],
			// 						[
			// 							-123.13866642537347,
			// 							49.24427232068231
			// 						],
			// 						[
			// 							-123.13829547018807,
			// 							49.24367127995826
			// 						],
			// 						[
			// 							-123.13787777516163,
			// 							49.24308233940671
			// 						],
			// 						[
			// 							-123.13741434655806,
			// 							49.242506917837055
			// 						],
			// 						[
			// 							-123.13690630081747,
			// 							49.241946401490246
			// 						],
			// 						[
			// 							-123.13635486186652,
			// 							49.241402140699265
			// 						],
			// 						[
			// 							-123.13576135816997,
			// 							49.24087544663602
			// 						],
			// 						[
			// 							-123.13512721953019,
			// 							49.240367588152644
			// 						],
			// 						[
			// 							-123.13445397364272,
			// 							49.239879788724714
			// 						],
			// 						[
			// 							-123.13374324241586,
			// 							49.23941322350377
			// 						],
			// 						[
			// 							-123.1329967380634,
			// 							49.238969016486294
			// 						],
			// 						[
			// 							-123.1322162589797,
			// 							49.23854823780591
			// 						],
			// 						[
			// 							-123.13140368540724,
			// 							49.2381519011553
			// 						],
			// 						[
			// 							-123.13056097490693,
			// 							49.23778096134417
			// 						],
			// 						[
			// 							-123.12969015764216,
			// 							49.23743631199903
			// 						],
			// 						[
			// 							-123.12879333148803,
			// 							49.23711878341033
			// 						],
			// 						[
			// 							-123.12787265697726,
			// 							49.23682914053225
			// 						],
			// 						[
			// 							-123.1269303520954,
			// 							49.236568081139865
			// 						],
			// 						[
			// 							-123.12596868693745,
			// 							49.2363362341481
			// 						],
			// 						[
			// 							-123.12498997823901,
			// 							49.236134158096675
			// 						],
			// 						[
			// 							-123.12399658379505,
			// 							49.235962339804495
			// 						],
			// 						[
			// 							-123.1229908967798,
			// 							49.23582119319688
			// 						],
			// 						[
			// 							-123.12197533998136,
			// 							49.23571105830836
			// 						],
			// 						[
			// 							-123.12095235996502,
			// 							49.23563220046354
			// 						],
			// 						[
			// 							-123.11992442117923,
			// 							49.23558480963787
			// 						],
			// 						[
			// 							-123.11889400001857,
			// 							49.235569
			// 						],
			// 						[
			// 							-123.11786357885786,
			// 							49.235584809636734
			// 						],
			// 						[
			// 							-123.11683564007195,
			// 							49.23563220046127
			// 						],
			// 						[
			// 							-123.11581266005538,
			// 							49.23571105830496
			// 						],
			// 						[
			// 							-123.11479710325663,
			// 							49.23582119319235
			// 						],
			// 						[
			// 							-123.11379141624099,
			// 							49.23596233979885
			// 						],
			// 						[
			// 							-123.11279802179655,
			// 							49.23613415808993
			// 						],
			// 						[
			// 							-123.11181931309753,
			// 							49.236336234140275
			// 						],
			// 						[
			// 							-123.11085764793893,
			// 							49.236568081130976
			// 						],
			// 						[
			// 							-123.10991534305633,
			// 							49.236829140522325
			// 						],
			// 						[
			// 							-123.10899466854474,
			// 							49.23711878339938
			// 						],
			// 						[
			// 							-123.1080978423897,
			// 							49.237436311987096
			// 						],
			// 						[
			// 							-123.10722702512396,
			// 							49.237780961331275
			// 						],
			// 						[
			// 							-123.1063843146226,
			// 							49.238151901141464
			// 						],
			// 						[
			// 							-123.10557174104902,
			// 							49.23854823779117
			// 						],
			// 						[
			// 							-123.10479126196412,
			// 							49.2389690164707
			// 						],
			// 						[
			// 							-123.10404475761041,
			// 							49.23941322348735
			// 						],
			// 						[
			// 							-123.10333402638223,
			// 							49.239879788707505
			// 						],
			// 						[
			// 							-123.10266078049338,
			// 							49.240367588134696
			// 						],
			// 						[
			// 							-123.10202664185216,
			// 							49.240875446617366
			// 						],
			// 						[
			// 							-123.10143313815412,
			// 							49.24140214067995
			// 						],
			// 						[
			// 							-123.10088169920164,
			// 							49.24194640147033
			// 						],
			// 						[
			// 							-123.10037365345944,
			// 							49.24250691781657
			// 						],
			// 						[
			// 							-123.09991022485426,
			// 							49.243082339385715
			// 						],
			// 						[
			// 							-123.09949252982615,
			// 							49.2436712799368
			// 						],
			// 						[
			// 							-123.09912157463904,
			// 							49.24427232066045
			// 						],
			// 						[
			// 							-123.09879825295647,
			// 							49.244884013596916
			// 						],
			// 						[
			// 							-123.0985233436887,
			// 							49.24550488512434
			// 						],
			// 						[
			// 							-123.09829750911621,
			// 							49.24613343950883
			// 						],
			// 						[
			// 							-123.09812129329428,
			// 							49.24676816250782
			// 						],
			// 						[
			// 							-123.09799512074227,
			// 							49.247407525018005
			// 						],
			// 						[
			// 							-123.0979192954209,
			// 							49.24804998675911
			// 						]
			// 					]
			// 				]
			// 			}
			// 		}
			// 	} )
			/* #endregion */

			// 	map.current.addLayer( {
			// 		'id': '10MinWalkLine',
			// 		'type': 'line',
			// 		'source': '10minwalk',
			// 		'layout': {},
			// 		'paint': {
			// 			'line-color': '#000',
			// 			'line-opacity': .33,
			// 			'line-width': 3,
			// 			// 'line-gap-width': 4,
			// 			'line-dasharray': [2, 10],
			// 		}
			// 	} );

			// 	map.current.addLayer( {
			// 		'id': '5MinWalkLine',
			// 		'type': 'line',
			// 		'source': '5minwalk',
			// 		'layout': {},
			// 		'paint': {
			// 			'line-color': '#000',
			// 			'line-opacity': .33,
			// 			'line-width': 3,
			// 			// 'line-gap-width': 4,
			// 			'line-dasharray': [2, 10],
			// 		}
			// 	} );


			// } );


			for ( const newMarker of neighGeo.features ) {
				// console.log( newMarker );
				// Create a DOM element for each marker.
				// const el = document.createElement('div');
				// const width = marker.properties.iconSize[0];
				// const height = marker.properties.iconSize[1];
				// el.className = classes.marker;

				let options = {};

				let marker = <div />;

				if ( newMarker.properties.Number === 0 ) {
					// el.classList.add( classes.ae );
					marker = <div className={`${classes.marker} ${classes.dev}`} />;
					options = {
						...options,
						anchor: "bottom",
					}
				} else {

					let markerStyle = "";
					if ( newMarker.properties.IsColorOutline !== "FALSE" ) {
						markerStyle = {
							color: newMarker.properties.Color,
							backgroundColor: "#FFFFFF",
							border: `2px ${newMarker.properties.Color} solid`,
						};
						// el.style.color = newMarker.properties.Color;
						// el.style.backgroundColor = "#FFFFFF";
						// el.style.border = `2px ${newMarker.properties.Color} solid`;
					} else {
						markerStyle = {
							backgroundColor: newMarker.properties.Color,
						}
						// el.style.backgroundColor = newMarker.properties.Color;
					}
					// el.innerText = newMarker.properties.Number;

					// el.onclick = () => {
					// 	map.current.flyTo( {
					// 		center: newMarker.geometry.coordinates,
					// 		speed: .5,
					// 	})
					// }

					marker =
						<div
							className={classes.marker}
							style={markerStyle}
							catid={newMarker.properties.CategoryId}
							poiid={newMarker.properties.Number}
							// whileHover={{ scale: 1.2 }}
							// onClick={() => {
							// 	// console.log( "coords", newMarker.geometry.coordinates );
							// 	// map.current.flyTo( {
							// 	// 	center: newMarker.geometry.coordinates,
							// 	// 	speed: .5,
							// 	// } );
							// }}
							onMouseEnter={() => props.setHighlightCatPoi( {
								isHighActive: true,
								highCatId: -1,
								highPoiId: newMarker.properties.Number.toString(),
							} )}
							onMouseLeave={() => props.setHighlightCatPoi( {
								isHighActive: false,
								highCatId: -1,
								highPoiId: -1,
							} )}
						// onHoverStart={() => props.setHighlightCatPoi( {
						// 	isHighActive: true,
						// 	highCatId: -1,
						// 	highPoiId: newMarker.properties.Number.toString()
						// } )}
						// onHoverEnd={() => props.setHighlightCatPoi( {
						// 	isHighActive: false,
						// 	highCatId: -1,
						// 	highPoiId: -1,
						// } )}
						>
							<div
								className={classes.markerDisplay}
							// style={markerStyle}
							// catid={newMarker.properties.CategoryId}
							// poiid={newMarker.properties.Number}
							>
								{newMarker.properties.Number}
							</div>
						</div>;

				}

				// const tooltipElm = document.createElement('div');
				// tooltipElm.className = classes.markerTooltip;
				// tooltipElm.innerText = newMarker.properties.Name;

				// el.appendChild(tooltipElm);


				// el.addEventListener('click', () => {
				// 	window.alert(newMarker.properties.Name);
				// });

				const popup = new mapboxgl.Popup( {
					offset: 25,
					// className: 'markerPopup',
					className: classes.markerPopup,
					closeButton: false,
				} )
					.setLngLat( newMarker.geometry.coordinates )
					.setHTML( `<h4>${newMarker.properties.Category}</h4></br><p>${newMarker.properties.Name}</p>` );

				const markerDiv = document.createElement( 'div' );
				ReactDOM.render( marker, markerDiv );

				const newMarkerObj = new mapboxgl.Marker( markerDiv, options )
					.setLngLat( newMarker.geometry.coordinates )
					.setPopup( popup )
					.addTo( map.current );

				// Add markers to the map.
				// new mapboxgl.Marker(el, options)
				setPoiMapboxMarkers( prevPoiMapboxMarkers => {
					return {
						...prevPoiMapboxMarkers,
						[newMarker.properties.Number]: newMarkerObj,
					};
				} )
				// console.log( el );
			}

			// Add walking distance icon markers
			// for ( const newMarker of walkingGeo.features ) {
			// 	let options = {
			// 		anchor: 'bottom',
			// 	};

			// 	let marker = <div />;

			// 	let markerStyle = {
			// 		color: newMarker.properties.Color,
			// 	}

			// 	marker =
			// 		<div
			// 			className={`${classes.marker} walkerMarker`}
			// 			style={markerStyle}
			// 			// whileHover={{ scale: 1.2 }}
			// 			onClick={() => {
			// 				map.current.flyTo( {
			// 					center: newMarker.geometry.coordinates,
			// 					speed: .5,
			// 				} );
			// 			}}
			// 		>
			// 			<div
			// 				className={`${classes.markerDisplay} walkerMarker`}
			// 			>
			// 				<img className={classes.walkingIcon} src={mapIconWalking} alt="Walking Distance Icon" /><br />
			// 				{newMarker.properties.Name}
			// 			</div>
			// 		</div>;

			// 	const markerDiv = document.createElement( 'div' );
			// 	ReactDOM.render( marker, markerDiv );

			// 	new mapboxgl.Marker( markerDiv, options )
			// 		.setLngLat( newMarker.geometry.coordinates )
			// 		.addTo( map.current );
			// }

			// console.log( 'poiMarkers:', poiMapboxMarkers );
		}
	} );


	useEffect( () => {
		if ( props.procFlyTo.doProcFlyTo ) {
			if ( props.procFlyTo.poiid != -1 ) {
				map.current.flyTo( {
					center: props.procFlyTo.coordinates,
					speed: 1,
				} );

				for ( const key in poiMapboxMarkers ) {
					if ( poiMapboxMarkers[key].getPopup().isOpen() )
						poiMapboxMarkers[key].togglePopup();
				};

				console.log( 'thisMarker', poiMapboxMarkers[props.procFlyTo.poiid], 'poiid', props.procFlyTo.poiid, 'markers', poiMapboxMarkers );
				poiMapboxMarkers[props.procFlyTo.poiid].togglePopup();
			}
		}
	}, [props.procFlyTo] );


	// useEffect( () => {
	// 	console.log( "pmm", poiMapboxMarkers );
	// }, [poiMapboxMarkers])

	return (
		<div ref={mapContainer} className={`${classes.map} ${doShow ? "doShow" : ""}`} />
	);
}

const Map = ( { doShow, ...props } ) => {

	const classes = useStyles();


	const { loading, error, data } = useQuery( QueryData.neighbourhoodLocations );

	if ( loading ) return RenderMap( classes, null, null, doShow, props );
	if ( error ) return RenderMap( classes, null, error, doShow, props );

	return RenderMap( classes, data, null, doShow, props );

};



export default Map;