import React from "react";

import {
	useQuery,
	gql,
} from "@apollo/client";

import {
	Link
} from "react-router-dom";

import {
	createUseStyles
} from "react-jss";

import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";


const mainMenuItemsQuery = gql`
	query mainMenuIconItems {
		mainMenuIconItems {
			id
			text
			url
			icon {
				url
				alternativeText
			}
			order
		}
	}
`;


const useStyles = createUseStyles( {
	wrapper: {
		marginTop: 30,

		textAlign: 'left',
		fontFamily: ThemeData.fonts.gothamBook,

		'& ul': {
			padding: 0,

			'& li': {
				margin: '15px 0',

				listStyleType: 'none',

				'& a': {
					display: 'flex',
					alignItems: 'center',
				},
				'& a, & a:visited': {
					color: ThemeData.colours.eggshellTextBg,
					textDecoration: 'none',
				},
			},
		},
	},
	hr: {
		margin: '20px 0',
	},
	iconItem: {
		fontSize: '15.2px',
		textTransform: 'uppercase'
	},
	iconItemIcon: {
		flex: '0 0 auto',
		display: 'inline-block',

		width: 16,
		height: 16,

		margin: 4,
		marginRight: 20,
	},
	iconItemText: {
		flex: '1 0 auto',

		letterSpacing: '.05em',
	},
} );


function MainMenuIconItems( { } ) {

	const classes = useStyles();

	const { loading, error, data } = useQuery( mainMenuItemsQuery );

	if( loading ) return <p></p>
	if ( error ) return <p>{console.log( error.message )}</p>

	// console.log( 'mmii', data );

	return (
		<>
			{data && data.mainMenuIconItems && data.mainMenuIconItems.length > 0 &&
				<>
					<hr className={classes.hr} />
					<div className={classes.wrapper}>
						<ul>
							{data.mainMenuIconItems.map( ( item ) => {
								return (
									<li className={classes.iconItem} key={item.id}>
										<Link to="/">
											<img className={classes.iconItemIcon} src={`${NetData.imgUri( item.icon.url )}`} alt={item.icon.alternativeText} />
											<div className={classes.iconItemText}>{item.text}</div>
										</Link>
									</li>
								);
							} )}
						</ul>
					</div>
				</>
			}
		</>
	);

}


export default MainMenuIconItems;