import React, {
	// useState,
	// useRef,
	// useEffect,
} from "react";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";

import useRegistration from "hooks/useRegistration";



import ThemeData from "../data/ThemeData";
import QueryData from "data/QueryData";


// import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
// import mapMarkerAmp from "img/map_marker_amp.svg";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'fixed',
			top: '50%',
			left: '50%',

			padding: 30,

			width: 750,
			height: 380,

			color: ThemeData.colours.white,
			backgroundColor: ThemeData.colours.green,

			textAlign: 'left',
		},
		title: {
			fontFamily: ThemeData.fonts.gothamBook,

			fontSize: 30,
		},
		label: {
			fontFamily: ThemeData.fonts.gothamMedium,
			fontSize: 10,
			textTransform: 'uppercase',
		},
		input: {
			border: 'none',
			borderBottom: `1px ${ThemeData.colours.clay} solid`,

			color: ThemeData.colours.white,
			backgroundColor: ThemeData.colours.green,

			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: 16,

			"&:focus, &:active": {
				outline: 'none',

				border: 'none',
				borderBottom: `1px ${ThemeData.colours.clay} solid`,
			},
		},
		firstName: {
			position: 'absolute',
			top: 50,
			left: 50,
		},
		lastName: {
			position: 'absolute',
			top: 50,
			left: 400,
		},
		email: {
			position: 'absolute',
			top: 150,
			left: 50,
		},
	},
	{
		name: "Register",
	}
)



function RenderRegister( classes, data, error, doShow, props ) {

	const { registration, setFirstName, setLastName, setEmail } = useRegistration();

	return (
		<motion.div 
			className={classes.wrapper}
			initial={false}
			animate={doShow ? "show" : "dontShow"}
			variants={{
				show: {
					x: '-50%',
					y: '-50%',
					zIndex: 4,
					display: 'block',
				},
				dontShow: {
					x: "100%",
					y: '-50%',
					display: 'block',
					transitionEnd: {
						zIndex: -2,
						display: 'none',
					}
				},
			}}
			transition={{
				duration: .5,
			}}
		>
			<form>
				<label className={classes.firstName}>
					First Name:<br /><br />
					<input className={classes.input} value={registration.fName} onChange={ evt => setFirstName( evt.target.value )}></input>
				</label><br /><br />
				<label className={classes.lastName}>
					Last Name:<br /><br />
					<input className={classes.input} value={registration.lName} onChange={ evt => setLastName( evt.target.value )}></input>
				</label><br /><br />
				<br />
				<label className={classes.email}>
					Email:<br /><br />
					<input className={classes.input} value={registration.email} onChange={ evt => setEmail( evt.target.value )}></input>
				</label>
			</form>
		</motion.div>
	);
}

const Register = ( { doShow, ...props } ) => {

	const classes = useStyles();

	let data = null;

	// const { loading, error, data } = useQuery( QueryData.neighbourhoodLocationCategories );

	// if( loading ) return RenderRegister( classes, null, null, doShow, props );
	// if( error ) return RenderRegister( classes, null, error, doShow, props );

	// console.log( data );

	return RenderRegister( classes, data, null, doShow, props );

};



export default Register;