import React from 'react';

import {
	createUseStyles,
} from "react-jss";


import ThemeData from "../data/ThemeData";



const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			marginTop: props.marginTop,
			padding: props.padding,

			// width: '100%',

			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',

			color: props.isActive ?
				( props.hoverTextColour ?
					props.hoverTextColour :
					props.backgroundColour ) :
				props.textColour,
			backgroundColor: props.isActive ?
				( props.hoverBackgroundColour ?
					props.hoverBackgroundColour :
					props.textColour ) :
				props.backgroundColour,

			fontSize: props.fontSize,
			textAlign: 'center',
			textTransform: 'uppercase',
			letterSpacing: '0.025em',

			border: props.border ? props.border : `1px ${props.borderColour} solid`,

			cursor: 'pointer',

			transition: 'color .25s ease-out, background-color .25s ease-out',

			'& a:hover, & a:visited': {
				color: props.textColour,
			},

			'&:hover': {
				color: props.isActive ?
					( props.hoverBackgroundColour ? props.hoverBackgroundColour : props.backgroundColour ) : 
					( props.hoverTextColour ? props.hoverTextColour : props.backgroundColour ),
				backgroundColor: props.isActive ?
					( props.hoverTextColour ? props.hoverTextColour : props.backgroundColour ) :
					( props.hoverBackgroundColour ? props.hoverBackgroundColour : props.textColour ),
			},
		} ),
	},
	{ name: 'ButtonOutline' },
);



ButtonOutline.defaultProps = {
	padding: '15px 10px',
	marginTop: 'initial',
	fontSize: 12,
	textColour: 'inherit',
	hoverTextColour: null,
	backgroundColour: 'clear',
	hoverBackgroundColour: null,
	border: "",
	borderColour: ThemeData.colours.grey,

	isActive: false,
	activeLabel: null,
};



function ButtonOutline( {children, ...props } ) {

	const classes = useStyles( props );

	return (
		<div className={`${props.className} ${classes.wrapper} ${props.firstInColl ? "firstInColl" : ""}`} onClick={props.onClick}>
			<div className={classes.buttonText}>
				{props.isActive && props.activeLabel ? props.activeLabel : props.label}
				{children}
			</div>
		</div>
	);
}



export default ButtonOutline;