import React, {
	// useState,
	// useRef,
	// useEffect,
} from "react";

import {
	useQuery,
	gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";


import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax


import ThemeData from "../data/ThemeData";
import QueryData from "data/QueryData";
import { useEffect } from "react";


// import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
// import mapMarkerAmp from "img/map_marker_amp.svg";



const useStyles = createUseStyles(
	{
		wrapper: {
			width: '100%',
			height: '100%',
			// height: 'calc( 100vh - 100px )',

		},
		title: {
			fontFamily: ThemeData.fonts.gothamBold,

			letterSpacing: '0.15em',
			textTransform: 'uppercase',
		},
		category: {
			paddingTop: 10,

			lineHeight: '3em',

			"& $categoryTitle, & $poi": {
				borderRadius: '7px',
			},
		},
		categoryTitle: {
			paddingLeft: 10,

			fontFamily: ThemeData.fonts.gothamBold,

			fontSize: 12,
			letterSpacing: '0.15em',
			textTransform: 'uppercase',
		},
		poi: {
			display: 'flex',
			width: '100%',

			fontFamily: ThemeData.fonts.gothamBook,

			fontSize: 16,
			// letterSpacing: '0.025em',
			letterSpacing: 0,
			textTransform: 'uppercase',
		},
		clickableItem: {
			cursor: 'pointer',
		},
		// "$categoryTitle": {
		// // "$categoryTitle, $poi": {
		// 	borderRadius: '7px',
		// },
		poiNumber: {
			paddingLeft: 10,

			flex: '0 0 auto',

			width: 30,

			letterSpacing: 0,
			textAlign: 'center',
		},
		poiName: {
			flex: '0 1 auto',

			minWidth: 0,
		},
	},
	{
		name: "NeighbourhoodPois",
	}
)



const highlightedPoiVariants = {
	highlighted: ( custom ) => ( {
		backgroundColor: `${custom}33`,
	} ),
	normal: ( custom ) => ( {
		backgroundColor: `${custom}00`,
	} ),
}




function RenderNeighbourhoodPois( classes, data, error, doShow, props ) {

	return (
		<div className={classes.wrapper}>
			<div className={classes.title}>
				Categories
			</div>
			{data && data.neighbourhoodLocationCategories && data.neighbourhoodLocationCategories.map( nlCat => (
				<div className={classes.category} key={nlCat.id}>
					<motion.div
						className={`${classes.categoryTitle} ${classes.clickableItem}`}
						style={{ color: `${nlCat.colour}` }}
						animate={props.highlightCatPoi.highCatId === nlCat.id ? "highlighted" : "normal"}
						custom={nlCat.colour}
						variants={highlightedPoiVariants}
						onMouseEnter={() => {
						// mouseenter={() => {
							// console.log( "catME" );
							props.setHighlightCatPoi( {
								isHighActive: true,
								highCatId: nlCat.id,
								highPoiId: -1,
							} )
						}}
						onMouseLeave={() => {
						// mouseleave={() => {
							// console.log( "catML" );
							props.setHighlightCatPoi( {
								isHighActive: false,
								highCatId: -1,
								highPoiId: -1,
							} )
						} }
						onTap={() => props.setProcFlyTo( {
							doProcFlyTo: true,
							catid: nlCat.id,
							poiid: -1,
							// coordinates: [poi.longitude, poi.latitude],
						} )}
					>
						{nlCat.name}
						{([ ...nlCat.neighbourhood_locations]
							.sort( (poiA, poiB) => poiA.number - poiB.number ))
							.map( poi => (
							<motion.div
								className={`${classes.poi} ${classes.clickableItem}`}
								animate={props.highlightCatPoi.highPoiId === poi.number ? "highlighted" : "normal"}
								custom={nlCat.colour}
								variants={highlightedPoiVariants}
								key={poi.id}
								nlcid={nlCat.id}
								nlid={poi.id}
								onMouseEnter={() => props.setHighlightCatPoi( {
									isHighActive: true,
									highCatId: -1,
									highPoiId: poi.number,
								} )}
								onMouseLeave={() => props.setHighlightCatPoi( {
									isHighActive: false,
									highCatId: -1,
									highPoiId: -1,
								} )}
								onTap={() => props.setProcFlyTo( {
									doProcFlyTo: true,
									catid: -1,
									poiid: poi.number,
									coordinates: [poi.longitude, poi.latitude],
								} )}
							>
								<div className={classes.poiNumber}>{poi.number}</div>
								<div className={classes.poiName}>{poi.name}</div>
							</motion.div>
						) )}
					</motion.div>
				</div>
			) )}
		</div>
	);
}

const ContentPageNeighbourhoodPois = ( { doShow, ...props } ) => {

	const classes = useStyles();

	const { loading, error, data } = useQuery( QueryData.neighbourhoodLocationCategories );

	if ( loading ) return RenderNeighbourhoodPois( classes, null, null, doShow, props );
	if ( error ) return RenderNeighbourhoodPois( classes, null, error, doShow, props );

	// console.log( data );

	return RenderNeighbourhoodPois( classes, data, null, doShow, props );

};



export default ContentPageNeighbourhoodPois;