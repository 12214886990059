import React, {
	useState,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	motion,
} from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";


import ThemeData from "data/ThemeData";
// import ConstantData from "data/ConstantData";
import QueryData from "data/QueryData";


import useSavedHomes from "hooks/useSavedHomes";
import preventClickEventPassthrough from "util/preventClickEventsPassthrough";
import UtilFcns from "util/UtilFcns";


// import UnitDetailListItem from "./UnitDetailListItem";
import UnitDetailCompareCard from "components/UnitDetailCompareCard";
import SavedHomesTitle from "components/SavedHomesTitle";
import ButtonOutline from "components/ButtonOutline";
// import ButtonGroup from "./ButtonGroup";


// import chevronLeftWhiteMedium from "img/chevron_left_white_medium.svg";
import xWhiteMedium from "img/x_white_medium.svg";
import iconChevronDown from "img/icon_chevron_down_filled_white.svg";
import SavedHomesCompareList from "./SavedHomesCompareList";


// import { calculateNormals } from "playcanvas";



const useStyles = createUseStyles(
	{
		wrapper: {
			// position: 'fixed',
			// top: '50%',
			// left: '50%',

			// width: 'calc( 100vw - 60px )',
			height: 'calc( 100vh - 200px )',
			boxSizing: 'border-box',
			// overflow: 'clip',

			padding: '0 35px',

			color: ThemeData.colours.white,
			backgroundColor: ThemeData.colours.green,

			letterSpacing: '.025em',
		},
		bgAmp: {
			zIndex: -1,
			position: 'absolute',
			left: -50,
			bottom: -100,

			color: ThemeData.colours.clay08,
			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: 500,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',

			width: '100%',
			height: '100%',
		},
		topRightContainer: {
			position: 'absolute',
			top: 60,
			right: 60,

			display: 'flex',
			alignItems: 'center',
		},
		closeX: {
			marginTop: 10,
			marginLeft: 50,

			cursor: 'pointer',
		},
		sendToEmailButton: {
			display: 'inline-block',

			width: 150,

			fontSize: 12,
			letterSpacing: '.1em',
		},
		savedHomesTitle: {
			textAlign: 'left',

			fontSize: 24,
			letterSpacing: '.15em',
		},
		top: {
			flex: '0 0 auto',

			// height: 50,
		},
		addHomesButton: {
			width: 220,

			fontSize: 12,
			letterSpacing: '.1em',

			borderRadius: 25,
		},
		middle: {
			flex: '1 0 auto',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',

			maxHeight: 'calc( 100vh - 200px )',

			// overflowX: 'auto',
			overflowY: 'auto',

			...ThemeData.styles.hideScrollbar,
		},
		unitDetailCompareCardWrapper: {
			flex: '0 0 auto',

			width: 575,
			// height: '100%',

			padding: 20,
		},
		unitCard: {
			color: ThemeData.colours.black,
		},
		bottom: {
			flex: '0 0 auto',
			display: 'flex',

			boxSizing: 'border-box',
			paddingTop: 25,

			height: 130,

			borderTop: `1px ${ThemeData.colours.white50} solid`,
		},
	},
	{
		name: "Units",
	},
)



const wrapperAnimVariants = {
	open: {
		x: '-50%',
		y: '-50%',
		zIndex: 4,
		display: 'block',
	},
	closed: {
		x: "100%",
		y: '-50%',
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}





const RenderFloorplans = ( classes, data, error, isOpen, toggleIsOpen, hooks, props ) => {

	// const [isSavedHomesCompareListOpen, setIsSavedHomesCompareListOpen] = useState( false );
	// const toggleIsSavedHomesCompareListOpen = () => setIsSavedHomesCompareListOpen( !isSavedHomesCompareListOpen );

	if( error ) {
		console.log( "RenderFloorplans", error.message );
	}

	return (
		<motion.div 
			className={classes.wrapper}
			// initial={false}
			// animate={isOpen ? "open" : "closed"}
			// variants={wrapperAnimVariants}
			// transition={{
			// 	type: "tween",
			// 	ease: "easeOut",
			// 	duration: .75,
			// }}
			onMouseDown={( e ) => preventClickEventPassthrough( e )}
			onMouseUp={( e ) => preventClickEventPassthrough( e )}
		>
			<div className={classes.contentContainer}>
				<div className={classes.top}>
					{/* <div className={classes.savedHomesTitle}>
						Floorplans
					</div> */}
				</div>
				<div className={classes.middle}>
					{data && data.units &&
						[...data.units].sort( ( unitA, unitB ) =>
							( UtilFcns.parseFloatSafe( unitA.interior_sqft ) + UtilFcns.parseFloatSafe( unitA.exterior_sqft ) ) -
							( UtilFcns.parseFloatSafe( unitB.interior_sqft ) + UtilFcns.parseFloatSafe( unitB.exterior_sqft ) )
						).map( unit => {

						// let unitIdx = data.units.findIndex( elm => elm.id == unit.id );

						// if ( unitIdx != -1 )
							return <div
								className={classes.unitDetailCompareCardWrapper}
								key={unit.id}
							>
								<UnitDetailCompareCard
									className={classes.unitCard}
									data={unit}
									toggleSavedHome={() => hooks.toggleSavedHome( unit.id )}
								/>
							</div>;

						return <></>;

					}
					)}
				</div>
				{/* <div className={classes.bottom}>
					<ButtonGroup className={classes.bottomButtons}>
						<ButtonOutline
							borderColour={ThemeData.colours.white}
							firstInColl
							className={classes.bottomButton}
						>
							Compare Homes
						</ButtonOutline>
						<ButtonOutline
							borderColour={ThemeData.colours.white}
							className={classes.bottomButton}
						>
							Send To Email
						</ButtonOutline>
					</ButtonGroup>
				</div> */}
				{/* <SavedHomesCompareList
					isOpen={isSavedHomesCompareListOpen}
					toggleIsOpen={() => toggleIsSavedHomesCompareListOpen()}
				/> */}
			</div>
		</motion.div>
	)
}



const Floorplans = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome } = useSavedHomes();
	let hooks = {
		savedHomes,
		toggleSavedHome,
	};


	const {loading, error, data} = useQuery( QueryData.units );    

	if( loading ) return RenderFloorplans( classes, null, null, isOpen, toggleIsOpen, hooks, props );
	if( error ) return RenderFloorplans( classes, null, error, isOpen, toggleIsOpen, hooks, props );

	console.log( "floorplans", data );

	return RenderFloorplans( classes, data, null, isOpen, toggleIsOpen, hooks, props );
	
}



export default Floorplans;