import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";


import UtilFcns from "util/UtilFcns";


import bedIcon from "../img/icon_bed.svg";
import bathIcon from "../img/icon_bath.svg";
import facingArrowIcon from "img/icon_facing_arrow.svg";



const useStyles = createUseStyles( 
	{
		wrapper: {
			// padding: 20,

			textAlign: 'left',
		},
		detailListItemsRow: ( props ) => ( {
			margin: 0,
			marginTop: props.marginTopItemsRow,
			padding: 0,

			display: 'flex',
			justifyContent: 'left',

			listStyleType: 'none',

			textAlign: 'left',
			letterSpacing: '.05em',
		} ),
		detailList: {
		},
		detailListItem: {
			width: 150,

			flex: '0 0 auto',
		},
		detailListItemWider: {
			width: 200,
		},
		detailListItemIcon: {

		},
		detailListItemInline: {
			display: 'inline-block',
			marginRight: 10,
		},
		detailListItemTitle: {
			color: ThemeData.colours.grey,

			fontFamily: ThemeData.fonts.gothamMedium,
			fontSize: 10,
			textTransform: 'uppercase',
		},
		detailListItemData: {
			marginTop: 10,

			letterSpacing: '.05em',
			// color: ThemeData.colours.green,
			fontSize: 16,
		},
		detailListItemDataModifier: {
			fontFamily: ThemeData.fonts.gothamBook,
		},
	},
	{
		name: "UnitInfoIconList",
	}
)


// data == UNIT
const UnitInfoIconList = ( { data, className, ...props } ) => {

	const classes = useStyles( props );


	return (
		<div className={`${classes.wrapper} ${className}`}>
			<ul className={`${classes.detailListItemsRow} ${classes.detailList}`} >
				<li className={classes.detailListItem}>
					<div className={classes.detailListItemTitle}>
						Interior
					</div>
					<div className={classes.detailListItemData}>
						{data && (
							<>
								{data.interior_sqft} <span className={classes.detailListItemDataModifier}>sqft</span>
							</>
						)}
					</div>
				</li>
				{data && data.exterior_sqft && (
					<li className={classes.detailListItem}>
						<div className={classes.detailListItemTitle}>
							Exterior
						</div>
						<div className={classes.detailListItemData}>

							<>
								{data.exterior_sqft} <span className={classes.detailListItemDataModifier}>sqft</span>
							</>
						</div>
					</li>
				)}
				<li className={classes.detailListItem}>
					<div className={classes.detailListItemTitle}>
						Total Space
					</div>
					<div className={classes.detailListItemData}>
						{data && (
							<>
								{UtilFcns.parseFloatSafe( data.interior_sqft ) + UtilFcns.parseFloatSafe( data.exterior_sqft )} <span className={classes.detailListItemDataModifier}>sqft</span>
							</>
						)}
					</div>
				</li>
			</ul>
			<ul className={`${classes.detailListItemsRow} ${classes.detailList}`} >
				{data && data.unit_home_type && (
					<li className={`${classes.detailListItem}`}>
						<div className={`${classes.detailListItemIcon} ${classes.detailListItemInline}`}>
							<img src={bedIcon} alt="Beds" />
						</div>
						<div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>
							{data.unit_home_type.number_beds} <span className={classes.detailListItemDataModifier}>{data.unit_home_type.text_beds}</span>
						</div>
					</li>
				)}
				{data && data.baths && (
					<li className={classes.detailListItem}>
						<div className={`${classes.detailListItemIcon} ${classes.detailListItemInline}`}>
							<img src={bathIcon} alt="Baths" />
						</div>
						<div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>
							{data.baths} <span className={classes.detailListItemDataModifier}>Bath</span>
						</div>
					</li>
				)}
				{props.doShowFacingItem && data && data.unit_exposures && (
					<li className={`${classes.detailListItem} ${classes.detailListItemWider}`}>
						<div className={`${classes.detailListItemIcon} ${classes.detailListItemInline}`}>
							<img src={facingArrowIcon} alt="Facing Direction Icon" />
						</div>
						<div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>
							{data.unit_exposures && data.unit_exposures.map( unitExposure => (
								<span key={unitExposure.id}>{unitExposure.direction}</span>
							) )} <span className={classes.detailListItemDataModifier}>facing</span>
						</div>
					</li>
				)}
			</ul>
		</div>
	);
}



UnitInfoIconList.defaultProps = {
	marginTopItemsRow: 50,

	doShowFacingItem: false,
}

export default UnitInfoIconList;