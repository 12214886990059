import React, {
	// useState,
	// useRef,
} from "react";

// import {
// 	useQuery,
// 	gql,
// } from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";


import HeaderSimple from "./HeaderSimple";


import ThemeData from "../data/ThemeData";
// import QueryData from "../data/QueryData";
import NetData from "../data/NetData";


import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
// import viewStudy722 from "../img/viewStudies/722-MaxSize.jpg";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: '100%',
			// height: 'calc( 100vh - 100px )',

			
		},
		header: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: 100,

			backgroundColor: ThemeData.colours.eggshellTextBg,
			// backgroundColor: ThemeData.colours.white50,

			zIndex: 2,
		},
		backButton: {
			width: 100,
			height: 100,

			cursor: 'pointer',

			color: '#FFF',

			background: 'no-repeat center',
			backgroundImage: `url( ${navArrowLeftDark} )`,
		},
		viewStudy: {
			paddingTop: 100,

			height: 'calc( 100vh - 100px )',
		},
	},
	{
		name: "ViewStudy",
	}
)



const wrapperAnimVariants = {
	open: {
		y: 0,
		zIndex: 4,
		display: 'block',
	},
	closed: {
		y: "100vh",
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}

function RenderViewStudy( classes, error, isOpen, toggleViewStudyFcn ) {

	console.log( 'ViewStudy isOpen', isOpen );

	const apiUri = NetData.apiUri;

	// const [yaw, setYaw] = React.useState(0);
	// const [pitch, setPitch] = React.useState(0);
	const panImage = React.useRef(null);

	return (
		<motion.div
			className={classes.wrapper}
			initial={false}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={{
				type: 'spring',
				bounce: .25,
				duration: .5,
			}}
		>
			<HeaderSimple
				backgroundColor={ThemeData.colours.eggshellTextBg}
				onBackClick={toggleViewStudyFcn} />
			<div className={classes.viewStudy}>
				{/* <div> Pitch: {pitch} </div>
				<div> Yaw: {yaw} </div> */}
				{/* <Pannellum
					ref={panImage}
					width="100%"
					height="calc( 100vh - 100px )"
					image={viewStudy722}
					pitch={10}
					yaw={180}
					hfov={75}
					autoLoad
					showZoomCtrl={false}
					onMouseup={( event ) => {
						setPitch( panImage.current.getViewer().mouseEventToCoords( event )[0] );
						setYaw( panImage.current.getViewer().mouseEventToCoords( event )[1] );
					}}
				>
				</Pannellum> */}
				<iframe
					title="View Study"
					ref={panImage}
					width="100%"
					// height="calc( 100vh - 100px )"
					height="100%"
					frameBorder="0"
					src="https://www.lngstudios.com/projects/test/"
				/>
			</div>
		</motion.div>
	);
}

					// {/* <Pannellum.Hotspot
					// 	type="custom"
					// 	pitch={12.41}
					// 	yaw={117.76}
					// 	handleClick={( evt, name ) => console.log( name )}
					// 	name="image info"
					// /> */}


const ViewStudy = ( { isOpen, toggleViewStudyFcn } ) => {

	const classes = useStyles();

	// const {loading, error, data} = useQuery( QueryData.unitById, {
	// 	variables: {
	// 		unitId: unitId,
	// 	}
	// } );

	// if ( loading ) return RenderUnitDetail( unitId, null, classes, null, isOpen, toggleUnitDetailFcn );
	// if ( error ) return RenderUnitDetail( unitId, null, classes, error, isOpen, toggleUnitDetailFcn );

	return RenderViewStudy( classes, null, isOpen, toggleViewStudyFcn );

};



export default ViewStudy;