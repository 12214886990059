const SlugData = {
	HOME_SLUG_NAME: "overview",
	determineCurrentLocation( locationPathname ) {
		let currentLocationPathname = locationPathname.slice( 1, locationPathname.length );
		if ( !currentLocationPathname ) {
			currentLocationPathname = this.HOME_SLUG_NAME;
		}
		return currentLocationPathname;
	},
}

export default SlugData;