import React from "react";

import {
	createUseStyles,
} from "react-jss";


// import ThemeData from "../data/ThemeData";



const useStyles = createUseStyles( {
	buttonGroupWrapper: props => ( {
		// marginTop: 20,

		display: 'flex',
		justifyContent: props.justifyContent,

		'& > div': {
			marginLeft: 20,

			// width: '100%',
		},
		'& >.firstInColl': {
			marginLeft: 0,
		},
	} ),
} );


ButtonGroup.defaultProps = {
	justifyContent: 'space-between',
}

function ButtonGroup( { children, ...props } ) {

	const classes = useStyles( props );

	return (
		<div className={`${props.className} ${classes.buttonGroupWrapper}`}>
			{children}
		</div>
	);
}



export default ButtonGroup;