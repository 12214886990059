import React, {
	// useState
} from "react";
import {
	Link
} from "react-router-dom";
import { createUseStyles } from "react-jss";


import { useQuery, gql } from "@apollo/client";

import {
	motion,
	useCycle,
} from "framer-motion";


import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";
import HeaderContextData from "../data/HeaderContextData";

import HeaderComponents from "./HeaderComponents";
import MainMenuIconItems from "./MainMenuIconItems";

import mainMenuOpenIcon from "../img/main_menu_open.svg";
import mainMenuCloseIcon from "../img/main_menu_close.svg";

const navQuery = gql`
	query NavItems {
		navItems(where: {
			nav: {
				slug: "topNav"
			}
		} ) {
			id
			order
			alt_name
			section {
				name
				slug
			}
		}
		contactInfo {
			email
			tel
			address
			map_image {
				url
				alternativeText
			}
			partner_images {
				id
				url
				alternativeText
			}
			tagline
			powered_by_image {
				url
				alternativeText
			}
		}
		contactSocials {
			id
			network_name
			link
			icon {
				url
				alternativeText
			}
		}
	}
`;

const useStyles = createUseStyles(
	{
		header: {
			flex: '0 0 auto',
			// display: 'flex',
			justifyContent: 'space-between',

			height: 100,

			// backgroundColor: ThemeData.colours.blackBg50,
			backgroundColor: ThemeData.colours.green,

			borderBottom: '1px rgb( 255, 255, 255, .5 ) solid',
		},
		mainMenuButton: {
			flex: '0 1 auto',

			width: 100,
			height: 100,

			cursor: 'pointer',

			background: 'no-repeat center',

			fontFamily: ThemeData.fonts.gothamBold,
		},
		openMainMenuButton: {
			backgroundImage: `url( ${mainMenuOpenIcon} )`,
		},
		closeMainMenuButton: {
			backgroundImage: `url( ${mainMenuCloseIcon} )`,
		},
		closeMainMenuText: {
			...ThemeData.typography.nameplate,

			cursor: 'pointer',
		},
		mainMenu: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',

			position: 'absolute',
			// top: 'calc(100px - 100vh)',
			top: 0,
			left: 0,

			width: '100%',
			height: '100%',

			display: 'none',

			'.isOpen &': {
				display: 'block',
			},

			backgroundColor: ThemeData.colours.green,
		},
		mainMenuHeader: {
			flex: '0 1 auto',

			width: '100%',
			height: 100,

			backgroundColor: 'inherit',
		},
		mainMenuTwoColumn: {
			display: 'flex',
			height: 'calc( 100vh - 100px )',
		},
		column: {
			flex: '1 1 auto',

			width: '50%',

			padding: '0 100px 0 100px',
		},
		leftColumn: {
			width: '50%',

			paddingTop: 40,

			'& hr': {
				margin: '20px 0',
			},

			borderRight: `1px ${ThemeData.colours.white50} solid`,
		},
		rightColumn: {
			width: '50%',

			paddingTop: 60,
		},
		columnContent: {
			padding: '0 100px 0 100px',

			textAlign: 'left',
		},
		mainMenuNav: {

			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: '3em',

			'& ul': {
				margin: 0,
				padding: 0,

				// height: 100,

				flex: '1 1 auto',

				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',

				'& li': {
					margin: '20px 0',

					flex: '0 1 auto',

					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',

					// borderRight: '1px rgb( 255, 255, 255, .5 ) solid',

					listStyleType: 'none',
					letterSpacing: 0,
				},
			},
		},
		labelTextColumn: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		itemLabelText: {
			flex: '1 0 auto',
			display: 'flex',
			justifyContent: 'start',

			marginBottom: 40,

			fontSize: '15.2px',
			textAlign: 'left',
			lineHeight: '23px',
		},
		itemLabel: {
			display: 'inline-block',
			width: 125,

			fontFamily: ThemeData.fonts.gothamBold,
			textTransform: 'uppercase',
			letterSpacing: 0,
		},
		itemText: {
			display: 'inline-block',

			fontFamily: ThemeData.fonts.gothamBook,
			letterSpacing: '.05em',
			whiteSpace: 'pre-wrap',

			'& img': {
				marginTop: 10,
			},
		},
		stickmap: {
			width: 271,
			// height: 225,
		},
		contactSocial: {
			display: 'inline-block',

			marginRight: 50,

			'& a, & a:visited': {
				color: ThemeData.colours.black,
			},
		},
		partnerImagesWrapper: {
			display: 'flex',
			alignItems: 'center',
		},
		partnerImage: {
			flex: '1 0 auto',

			marginRight: 40,
		},
		poweredByProximaWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',

			width: '100%',
		},
		poweredByProximaText: {
			width: 400,

			fontSize: '9.5px',
			textAlign: 'right',
			marginRight: '35px',
			letterSpacing: '.335em',
		},
		poweredByProximaImg: {

		},
	},
	{
		name: "HeaderContainer",
	}
);

const variants = {
	open: {
		// opacity: 1,
		y: 0,
		zIndex: 4,
		display: 'block',
	},
	closed: {
		// opacity: 0,
		y: "-100vh",
		display: 'block',
		transitionEnd: { zIndex: -2, display: 'none', },
	}
}


const HeaderContainer = ( { ...props } ) => {

	const [isOpen, toggleOpen] = useCycle( false, true );
	const classes = useStyles();

	const { loading, error, data } = useQuery( navQuery );

	if ( loading ) return <p></p>
	if ( error ) return <p>{console.log('hco', error.message )}</p>

	// console.log( data );

	return (
		<header className={`${classes.header}`}>
			<HeaderComponents
				headerContext={HeaderContextData.siteHeaderContext}
				toggleIsSavedHomesSlideoutOpen={() => props.toggleIsSavedHomesSlideoutOpen()}
				toggleIsRegisterOpen={() => props.toggleIsRegisterOpen()}
			>
				{/* <div className={`${classes.mainMenuButton} ${classes.openMainMenuButton}`} onClick={() => toggleOpen( isOpen => !isOpen )} /> */}
			</HeaderComponents>
			<motion.div
				className={classes.mainMenu}
				initial={false}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ type: 'spring', bounce: .25, duration: .5 }}
			>
				<header className={`${classes.header} ${classes.mainMenuHeader}`}>
					<HeaderComponents headerContext={HeaderContextData.mainMenuHeaderContext}>
						<div className={`${classes.mainMenuButton} ${classes.closeMainMenuButton}`} onClick={() => toggleOpen( isOpen => !isOpen )} />
						<div className={`${classes.closeMainMenuText}`} onClick={() => toggleOpen( isOpen => !isOpen )}>
							<p>Close Menu</p>
						</div>
					</HeaderComponents>
				</header>
				{/* <Nav navLocation="topNav" /> */}
				<div className={classes.mainMenuTwoColumn}>
					<div className={classes.leftColumn}>
						<div className={classes.columnContent} >
							<div className={classes.mainMenuNav}>
								<ul>
									{data.navItems.map( ( navItem, idx ) => {
										return (
											<li key={navItem.id}>
												{navItem.alt_name ? navItem.alt_name : navItem.section.name}
											</li>
										)
									} )}
								</ul>
							</div>
							{/* <hr /> */}
							<MainMenuIconItems />
						</div>
					</div>
					<div className={classes.rightColumn}>
						<div className={classes.columnContent}>
							<div className={classes.labelTextColumn}>
								<div className={classes.itemLabelText}>
									<div className={classes.itemLabel}>
										Contact
									</div>
									<div className={classes.itemText}>
										{data.contactInfo.email}<br />
										{data.contactInfo.tel}
									</div>
								</div>
								<div className={classes.itemLabelText}>
									<div className={classes.itemLabel}>
										Visit Us
									</div>
									<div className={classes.itemText}>
										{data.contactInfo.address}<br />
										<img className={classes.stickmap} src={`${NetData.imgUri( data.contactInfo.map_image.url ) }`} alt={data.contactInfo.map_image.alternativeText} />
										{/* {data.contactInfo.address.replace( /(?:\r\n|\r|\n)/g, <br /> )} */}
										{/* // && console.log( data.contactInfo.address )} */}
									</div>
								</div>
								{data && data.contactSocials && data.contactSocials.length > 0 &&
									<div className={classes.itemLabelText}>
										<div className={classes.itemLabel}>
											Socials
										</div>
										<div className={classes.itemText}>
											{data.contactSocials.map( ( contactSocial ) => {
												return (
													<div className={classes.contactSocial} key={contactSocial.id}>
														<a href={contactSocial.link}>
															{contactSocial.network_name}
														</a>
													</div>
												);
											} )}
										</div>
									</div>}
								{( data.contactInfo.partner_images && data.contactInfo.partner_images.length > 0 ) &&
									<div className={classes.itemLabelText}>
										<div className={classes.itemLabel}>
											Our Team
										</div>
										<div className={classes.itemText}>
											<div className={classes.partnerImagesWrapper}>
												{data.contactInfo.partner_images.map( ( partnerImage ) => {
													return (
														<div className={classes.partnerImage} key={partnerImage.id}>
															<img src={`${NetData.imgUri( partnerImage.url )}`} alt={partnerImage.alternativeText} />
														</div>
													);
												} )}
											</div>
										</div>
									</div>
								}
								<div className={classes.poweredByProximaWrapper}>
									<div className={`${classes.itemLabel} ${classes.poweredByProximaText}`}>
										{data.contactInfo.tagline}
									</div>
									<div className={classes.poweredByProximaImg}>
										<img src={`${NetData.imgUri( data.contactInfo.powered_by_image.url )}`} alt={data.contactInfo.powered_by_image.alternativeText} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</motion.div>
		</header>
	)

};


export default HeaderContainer;