import React from "react";

import {
	useQuery,
	gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";


import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import ButtonCircle from "./ButtonCircle";
import HeaderSimple from "./HeaderSimple";
import FloorplanSelectionSpinner from "./FloorplanSelectionSpinner";


import ThemeData from "../data/ThemeData";
import QueryData from "../data/QueryData";
import NetData from "../data/NetData";


// import navArrowLeft from "../img/nav_arrow_left.svg";
import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
import floorPlateLocationImg from "../img/floorplate_location_example.png";
import northArrowIcon from "../img/icon_north_arrow.svg";

import plusIcon from "../img/icon_plus.svg";
import downloadIcon from "../img/icon_download.svg";
import planeIcon from "../img/icon_paper_plane.svg";
import bedIcon from "../img/icon_bed.svg";
import bathIcon from "../img/icon_bath.svg";
import useSavedHomes from "hooks/useSavedHomes";
import UnitInfoIconList from "./UnitInfoIconList";





const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: '100%',
			// height: 'calc( 100vh - 100px )',

			
		},
		header: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: 100,

			backgroundColor: ThemeData.colours.blackBg5,
		},
		backButton: {
			width: 100,
			height: 100,

			cursor: 'pointer',

			color: '#FFF',

			background: 'no-repeat center',
			backgroundImage: `url( ${navArrowLeftDark} )`,
		},
		twoColumn: {
			display: 'flex',
			justifyContent: 'center',

			height: '100%',
		},
		column: {
			flex: '1 0 auto',

			boxSizing: 'border-box',
			width: 'calc( 50% - 200px )',

			padding: '150px 50px 50px 50px',
		},
		leftColumn: {
			backgroundColor: ThemeData.colours.white,
		},
		floorPlan: {

		},
		floorPlanImg: {
			height: '100%',
			width: '100%',

			objectFit: 'contain',
		},
		floorPlanButtons: {
			margin: '50px auto 0 auto',

			maxWidth: 350,
		},
		floorPlateLocation: {
			paddingTop: 50,

			display: 'flex',
			justifyContent: 'center',
		},
		nArrow: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',

			marginRight: 50,
		},
		nArrowArrow: {
			flex: '0 0 auto',

			width: 20,
			height: 33,
		},
		nArrowN: {
			flex: '0 0 auto',

			color: ThemeData.colours.green,
			fontFamily: ThemeData.fonts.gothamMedium,
			fontSize: 23,
		},
		floorPlateLocationImg: {
			flex: '0 0 auto',

			maxHeight: 160,

			objectFit: 'contain',
		},
		rightColumn: {
			backgroundColor: ThemeData.colours.eggshellTextBg,

			paddingLeft: 100,
			paddingRight: 100,
		},
		hr: {
			width: '100%',

			marginTop: 35,

			borderTop: `1px ${ThemeData.colours.grey25} solid`,
		},
		actionButtons: {
			width: '100%',

			marginTop: 35,
		},
		detailListItems: {
			margin: 0,
			marginTop: 50,
			padding: 0,

			display: 'flex',
			justifyContent: 'left',

			listStyleType: 'none',

			textAlign: 'left',
			letterSpacing: '.05em',
		},
		detailList: {
		},
		detailListItem: {
			width: 150,

			flex: '0 0 auto',
		},
		detailListItemIcon: {

		},
		detailListItemInline: {
			display: 'inline-block',
			marginRight: 10,
		},
		detailListItemTitle: {
			color: ThemeData.colours.grey,

			fontFamily: ThemeData.fonts.gothamMedium,
			fontSize: 10,
			textTransform: 'uppercase',
		},
		detailListItemData: {
			marginTop: 10,

			letterSpacing: '.05em',
			color: ThemeData.colours.green,
			fontSize: 16,
		},
		detailListItemDataModifier: {
			fontFamily: ThemeData.fonts.gothamBook,
		},
		unitDescription: {
			marginTop: 50,

			color: ThemeData.colours.grey,

			textAlign: 'left',
			lineHeight: '1.9em',
			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: 16,
			letterSpacing: '.025em',
		},
		furtherUnitDetailsButtons: {
			marginTop: 50,
		},
	},
	{
		name: "UnitDetails",
	}
)



const wrapperAnimVariants = {
	open: {
		y: 0,
		zIndex: 4,
		display: 'block',
	},
	closed: {
		y: "100vh",
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}



function RenderUnitDetail( unitId, data, classes, error, isOpen, toggleUnitDetailFcn, hooks ) {

	// console.log( 'UnitDetail isOpen', isOpen );

	const apiUri = NetData.apiUri;

	return (
		<motion.div
			className={classes.wrapper}
			initial={false}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={{
				type: 'spring',
				bounce: .25,
				duration: .5,
			}}
		>
			<HeaderSimple
				backgroundColor={ThemeData.colours.blackBg5}
				onBackClick={toggleUnitDetailFcn} />
			<div className={classes.twoColumn}>
				<div className={`${classes.column} ${classes.leftColumn}`}>
					<div className={classes.floorPlan}>
						{data && data.unit && data.unit.unit_plan_type && data.unit.unit_plan_type.image &&
							<img
							className={classes.floorPlanImg}
							src={NetData.imgUri( data && data.unit && data.unit.unit_plan_type.image.url )}
							alt={data && data.unit && data.unit.unit_plan_type.image.alternativeText} />
						}
						{( !data || !data.unit || !data.unit.unit_plan_type || !data.unit.unit_plan_type.image ) &&
							<img
								className={classes.floorPlanImg}
								src={`${apiUri}/uploads/floorplan_placeholder_large_1680295ea2.png`}
								alt={'floorplan placeholder large'} />
						}
					</div>
					<div className={classes.floorPlanButtons}>
						<ButtonGroup>
							<ButtonOutline
								label="Enjoy the View"
								padding={20}
								textColour={ThemeData.colours.clay}
								hoverTextColour={ThemeData.colours.white}
								borderColour={ThemeData.colours.grey25}
								to="."
								firstInColl
							/>
							<ButtonOutline
								label="Enter Showhome"
								padding={20}
								textColour={ThemeData.colours.clay}
								hoverTextColour={ThemeData.colours.white}
								borderColour={ThemeData.colours.grey25}
								to="."
							/>
						</ButtonGroup>
					</div>
					<div className={classes.floorPlateLocation}>
						<div className={classes.nArrow}>
							<img
								className={classes.nArrowArrow}
								src={northArrowIcon}
								alt="north arrow icon" />
							<div className={classes.nArrowN}>
								N
							</div>
						</div>
						<img
							className={classes.floorPlateLocationImg}
							src={floorPlateLocationImg}
							alt="floorplate location for this plan" />
					</div>
				</div>
				<div className={`${classes.column} ${classes.rightColumn}`}>
					<FloorplanSelectionSpinner
						selectedFloorplanId={data && data.unit && data.unit.unit_plan_type && data.unit.unit_plan_type.id}
						floorplanName={data && data.unit && data.unit.unit_plan_type && data.unit.unit_plan_type.name}
						floorplanHomeType={data && data.unit && data.unit.unit_home_type && data.unit.unit_home_type.name}
					/>
					<div className={classes.hr} />
					<div className={classes.actionButtons}>
						<ButtonGroup>
							<ButtonOutline
								label="Book An Appointment"
								padding={20}
								textColour={ThemeData.colours.clay}
								hoverTextColour={ThemeData.colours.white}
								borderColour={ThemeData.colours.grey25}
								to="."
								firstInColl
							></ButtonOutline>
							<ButtonCircle
								src={plusIcon}
								alt="Add Floorplan To Saved"
								textColour={ThemeData.colours.clay25}
								borderColour={ThemeData.colours.grey25}
								onClick={() => hooks.toggleSavedHome( data.unit.id )}
							></ButtonCircle>
							<ButtonCircle
								src={downloadIcon}
								alt="Download PDF"
								textColour={ThemeData.colours.clay25}
								borderColour={ThemeData.colours.grey25}
							></ButtonCircle>
							<ButtonCircle
								src={planeIcon}
								alt="Add Floorplan To Saved"
								textColour={ThemeData.colours.clay25}
								borderColour={ThemeData.colours.grey25}
							></ButtonCircle>
						</ButtonGroup>
					</div>
					<div className={classes.hr} />
					<UnitInfoIconList
						data={data && data.unit}
					/>
					{/* <ul className={`${classes.detailListItems} ${classes.detailList}`} >
						<li className={classes.detailListItem}>
							<div className={classes.detailListItemTitle}>
								Interior
							</div>
							<div className={classes.detailListItemData}>
								{data && data.unit && (
									<>
										{data.unit.interior_sqft} <span className={classes.detailListItemDataModifier}>sqft</span>
									</>
								)}
							</div>
						</li>
						{data && data.unit && data.unit.exterior_sqft && (
							<li className={classes.detailListItem}>
								<div className={classes.detailListItemTitle}>
									Exterior
								</div>
								<div className={classes.detailListItemData}>

									<>
										{data.unit.exterior_sqft} <span className={classes.detailListItemDataModifier}>sqft</span>
									</>
								</div>
							</li>
						)}
						<li className={classes.detailListItem}>
							<div className={classes.detailListItemTitle}>
								Total Space
							</div>
							<div className={classes.detailListItemData}>
								{data && data.unit && (
									<>
										{data.unit.interior_sqft} <span className={classes.detailListItemDataModifier}>sqft</span>
									</>
								)}
							</div>
						</li>
					</ul>
					<ul className={`${classes.detailListItems} ${classes.detailList}`} >
						{data && data.unit && data.unit.unit_home_type && (
							<li className={`${classes.detailListItem}`}>
								<div className={`${classes.detailListItemIcon} ${classes.detailListItemInline}`}>
									<img src={bedIcon} alt="Beds" />
								</div>
								<div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>
									{data.unit.unit_home_type.number_beds} <span className={classes.detailListItemDataModifier}>{data.unit.unit_home_type.text_beds}</span>
								</div>
							</li>
						)}
						{data && data.unit && data.unit.baths && (
							<li className={classes.detailListItem}>
								<div className={`${classes.detailListItemIcon} ${classes.detailListItemInline}`}>
									<img src={bathIcon} alt="Baths" />
								</div>
								<div className={`${classes.detailListItemData} ${classes.detailListItemInline}`}>
									{data.unit.baths} <span className={classes.detailListItemDataModifier}>Bath</span>
								</div>
							</li>
						)}
					</ul> */}
					<div className={classes.unitDescription}>
						<p>With a sleek, current architectural envelope and minimal, modernist interior touches, Ash &amp; Edward’s bold design asks its residences to consider what they love and need most in a home, and demand the best of it.</p>
					</div>
					<div className={classes.furtherUnitDetailsButtons}>
						<ButtonGroup justifyContent="center">
							<ButtonOutline
								label="Features"
								padding={20}
								textColour={ThemeData.colours.clay}
								hoverTextColour={ThemeData.colours.white}
								borderColour={ThemeData.colours.grey25}
								to="."
								firstInColl
							/>
							<ButtonOutline
								label="Pricing"
								padding={20}
								textColour={ThemeData.colours.clay}
								hoverTextColour={ThemeData.colours.white}
								borderColour={ThemeData.colours.grey25}
								to="."
							/>
						</ButtonGroup>
					</div>
				</div>
			</div>
		</motion.div>
	);
}



const UnitDetail = ( { unitId, isOpen, toggleUnitDetailFcn } ) => {

	const classes = useStyles();

	const { savedHomes, toggleSavedHome } = useSavedHomes();

	let hooks = {
		savedHomes,
		toggleSavedHome,
	}

	const {loading, error, data} = useQuery( QueryData.unitById, {
		variables: {
			unitId: unitId,
		}
	} );

	if ( loading ) return RenderUnitDetail( unitId, null, classes, null, isOpen, toggleUnitDetailFcn, hooks );
	if ( error ) return RenderUnitDetail( unitId, null, classes, error, isOpen, toggleUnitDetailFcn, hooks );

	return RenderUnitDetail( unitId, data, classes, null, isOpen, toggleUnitDetailFcn, hooks );

};



export default UnitDetail;