import React from 'react';

import {
	createUseStyles,
} from "react-jss";


import ThemeData from "../data/ThemeData";


const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			// marginTop: props.marginTop,
			// padding: props.padding,

			width: props.widthHeight,
			height: props.widthHeight,

			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',

			color: props.textColour,
			backgroundColor: props.backgroundColour,

			fontSize: props.fontSize,
			textAlign: 'center',
			textTransform: 'uppercase',
			letterSpacing: '0.025em',

			borderRadius: '50%',
			border: props.border ? props.border : `1px ${props.borderColour} solid`,

			cursor: 'pointer',

			transition: 'color .25s ease-out, background-color .25s ease-out',

			'& a:hover, & a:visited': {
				color: props.textColour,
			},

			'&:hover': {
				color: props.hoverTextColour ? props.hoverTextColour : props.backgroundColour,
				backgroundColor: props.textColour,
			},
		} ),
		icon: props => ( {
			width: props.iconWidthHeight,
			height: props.iconWidthHeight,
		})
	},
	{ name: 'ButtonCircle' },
);


ButtonCircle.defaultProps = {
	widthHeight: 55,
	iconWidthHeight: 32,
	padding: '15px 5px',
	marginTop: 'initial',
	fontSize: 12,
	textColour: 'inherit',
	hoverTextColour: null,
	backgroundColour: 'clear',
	border: "",
	borderColour: ThemeData.colours.grey,
};


function ButtonCircle( {children, ...props } ) {

	const classes = useStyles( props );

	return (
		<div className={`${classes.wrapper} ${props.firstInColl ? "firstInColl" : ""} ${props.className}`} onClick={props.onClick}>
			<div className={classes.buttonText}>
				<img className={classes.icon} src={props.src} alt={props.alt} />
				{children}
			</div>
		</div>
	);
}



export default ButtonCircle;