import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";
import NetData from "data/NetData";

import UnitInfoPlanNameTitle from "./UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";

import ButtonGroup from "components/ButtonGroup";
import ButtonCircle from "components/ButtonCircle";
import ButtonOutline from "components/ButtonOutline";


import xClaySmall from "img/x_clay_small.svg";
import UnitInfoIconList from "./UnitInfoIconList";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'relative',
			// height: '100%',

			...ThemeData.styles.hoverCard,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			boxSizing: 'border-box',
			// alignItems: 'center',

			padding: 20,

			textAlign: 'left',
		},
		hr: {
			width: '100%',

			marginTop: 35,

			borderTop: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
		removeHomeButton: {
			position: 'absolute',
			top: 20,
			right: 20,

			width: 48,
		},
		floorplanContainer: {
			flex: '0 0 auto',

			// width: 105,
			height: 300,

			marginRight: 25,

			borderRadius: 4,

			backgroundColor: ThemeData.colours.white,
		},
		floorplanImg: {
			height: '100%',
			width: '100%',

			objectFit: 'contain',
		},
		floorKeyPlanButtons: {
			borderRadius: 50,
		},
		info: {
			flex: '1 0 auto',
		},
		unitInfoPlanNameTitle: {
			marginTop: 0,
		},
		unitInfoLine: {
			color: ThemeData.colours.grey,

			fontSize: 16,
		},
		unitInfoIconList: {
		},
		viewDetailButtonArea: {
			paddingTop: 10,
		},
	},
	{
		name: "UnitDetailCompareCard",
	}
)



const UnitDetailCompareCard = ( { data, ...props } ) => {

	const classes = useStyles();

	const apiUri = NetData.apiUri;

	// console.log( data );

	return (
		<div className={`${classes.wrapper} ${props.className}`}>
			<div className={classes.contentContainer}>
				{/* <div className={classes.removeHomeButton}>
					<ButtonCircle
						src={xClaySmall}
						alt="Remove This Saved Home"
						widthHeight={46}
						iconWidthHeight={26}
						borderColour={ThemeData.colours.clay25}
						textColour={ThemeData.colours.clay25}
						onClick={() => props.toggleSavedHome()}
					/>
				</div> */}
				<div className={classes.info}>
					<UnitInfoPlanNameTitle
						data={data}
						color={ThemeData.colours.black}
						fontSize={36}
						doShowUnitNumber={false}
						className={classes.unitInfoPlanNameTitle}
					/>
					<UnitInfoLine
						data={data}
						className={classes.unitInfoLine}
						doShowFloor={true}
						doShowUnitNumber={true}
						doShowHomeType={false}
						doShowBaths={false}
						doShowIntSqft={false}
					/>
				</div>
				<div className={classes.floorplanContainer}>
					{data && data.unit_plan_type && data.unit_plan_type.image &&
						<img
							className={classes.floorplanImg}
							src={NetData.imgUri( data && data.unit_plan_type.image.url )}
							alt={data && data.unit_plan_type.image.alternativeText} />
					}
					{( !data || !data.unit_plan_type || !data.unit_plan_type.image ) &&
						<>
							{data.floorplan_image && 
								<img
									className={classes.floorplanImg}
									src={NetData.imgUri( data && data.floorplan_image.url )}
									alt={data && data.floorplan_image.alternativeText} />
								// src={`${apiUri}/uploads/floorplan_placeholder_large_1680295ea2.png`}
								// alt={'floorplan placeholder large'} />
							}
						</>
					}
				</div>
				{/* <div className={classes.floorplanImg}>
				{data.floorplan_image && <img
					src={`${apiUri}${data.floorplan_image.formats && data.floorplan_image.formats.small.url}`}
				/>}
			</div> */}
				<div className={classes.planButtonsContainer}>
					<ButtonGroup
						justifyContent={'center'}
					>
						<ButtonOutline
							borderColour={ThemeData.colours.grey25}
							className={classes.floorKeyPlanButtons}
							textColour={ThemeData.colours.clay}
							hoverTextColour={ThemeData.colours.white}
							hoverBackgroundColour={ThemeData.colours.clay}
							firstInColl
							onClick={() => {
							}}

							isActive={true}
							label={"Floor Plan"}
						>
						</ButtonOutline>
						<ButtonOutline
							borderColour={ThemeData.colours.grey25}
							className={classes.floorKeyPlanButtons}
							textColour={ThemeData.colours.clay}
							hoverTextColour={ThemeData.colours.white}
							hoverBackgroundColour={ThemeData.colours.clay}
							onClick={() => {
							}}
						>
							Key Plan
						</ButtonOutline>
					</ButtonGroup>
				</div>
				<div className={classes.hr} />
				<UnitInfoIconList
					className={classes.unitInfoIconList}
					data={data}
					marginTopItemsRow={25}
					doShowFacingItem={false}
				/>
			</div>
			{/* <div className={classes.viewDetailButtonArea}>
				<ButtonOutline
					padding={20}
					textColour={ThemeData.colours.clay}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.eggshellTextBg}
				>
					View Home In Detail
				</ButtonOutline>
			</div> */}
		</div>
	);
}



export default UnitDetailCompareCard;