const UtilFcns = {
	// Sends anything not a number to 0
	parseFloatSafe: ( toParse ) => {
		if (
			isNaN( toParse ) ||
			toParse === null ||
			( typeof ( toParse.trim ) === "function" && toParse.trim() === "" ) )
			return 0;
	
		return parseFloat( toParse );
	},
}



export default UtilFcns;
