import createPersistedState from "use-persisted-state";

import ConstantData from "data/ConstantData";


const useRegistrationState = createPersistedState( ConstantData.localStorageKeys.registration );


const useRegistration = () => {

    const [registration, setRegistration] = useRegistrationState( {
        fName: "",
        lName: "",
        email: "",
    } );

    return {
        registration,
        setFirstName: fName => {
            setRegistration( {...registration, fName: fName } );
        },
        setLastName: lName => {
            setRegistration( {...registration, lName: lName } );
        },
        setEmail: email => {
            setRegistration( {...registration, email: email } );
        },
    }

}


export default useRegistration;